// Dependencies
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Material Components
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

// Helpers
import { validIngredientSearchInput } from 'lib/helpers/ingredientSwapper';

const SearchField = ({ onGetTargetIngredient, classes }) => {
  // Lifecycle methods
  const [ingredientSearchInput, setIngredientSearchInput] = useState('');
  const [ingredientSearchInputError, setIngredientSearchInputError] = useState(false);
  const [ingredientSearchInputErrorMessage, setIngredientSearchInputErrorMessage] = useState('');

  // Handler methods
  const handleSetIngredientSearchInput = (event) => {
    const { value } = event.target;
    const trimmedValue = value.trim();
    setIngredientSearchInput(trimmedValue);
    setIngredientSearchInputError(false);
    setIngredientSearchInputErrorMessage('');
  };

  const handleGetTargetIngredient = () => {
    if (validIngredientSearchInput(ingredientSearchInput)) {
      onGetTargetIngredient(ingredientSearchInput);
    } else {
      setIngredientSearchInputError(true);
      setIngredientSearchInputErrorMessage('Enter a valid Ingredient ID');
    }
  };

  const handleEnterIngredientSearchInput = (event) => {
    if (event.key === 'Enter') {
      handleGetTargetIngredient();
    }
  };

  // Rendering
  return (
    <>
      <h3 className={classes.title}>1. Select New Ingredient</h3>
      <Grid container spacing={3}>
        <Grid item>
          <TextField
            id="target-ingredient-id"
            variant="outlined"
            label="Ingredient ID"
            size="small"
            color="secondary"
            value={ingredientSearchInput}
            error={ingredientSearchInputError}
            helperText={ingredientSearchInputErrorMessage}
            onChange={handleSetIngredientSearchInput}
            onKeyDown={handleEnterIngredientSearchInput} // Search by hitting the enter key
          />
        </Grid>
        <Grid item>
          <Button variant="outlined" color="primary" onClick={handleGetTargetIngredient}>
            Select
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

SearchField.propTypes = {
  onGetTargetIngredient: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default SearchField;
