import { IM_FORM_OPTIONS, IM_INCIDENT } from 'lib/constants';
import { makeApiRequest } from 'lib/api';
import { createIncidents, fetchFormOptions } from './services';

export const getFormOptions = () => {
  return async (dispatch) => {
    await dispatch(
      makeApiRequest({
        type: IM_FORM_OPTIONS,
        method: fetchFormOptions,
      })
    );
  };
};

export const submitIncidents = (formState) => {
  return async (dispatch) => {
    await dispatch(
      makeApiRequest({
        type: IM_INCIDENT,
        method: createIncidents,
        params: formState,
      })
    );
  };
};
