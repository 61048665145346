import { IM_INCIDENTABLE, IM_TAX_REFUND, IM_GIFT_CARD_TAX_REFUND, IM_CANCEL } from 'lib/constants';
import { makeApiRequest } from 'lib/api';
import Logger from 'lib/Logger';
import { toggleSnackBar } from '../snackBar/actions';
import {
  fetchIncidentable,
  fetchTaxRefund,
  fetchGiftCardTaxRefund,
  createCancellation,
} from './services';

export const getIncidentable = (incidentableId, incidentableType) => {
  return async (dispatch) => {
    await dispatch(
      makeApiRequest({
        type: IM_INCIDENTABLE,
        method: fetchIncidentable,
        params: { incidentableId, incidentableType },
      })
    );
  };
};

export const getTaxRefund = (incidentableId, incidentableType, refundCents) => {
  return async (dispatch) => {
    try {
      const response = await dispatch(
        makeApiRequest({
          type: IM_TAX_REFUND,
          method: fetchTaxRefund,
          params: { incidentableId, incidentableType, refundCents },
        })
      );

      const { taxRefundCents } = response;
      const totalRefundCents = refundCents + taxRefundCents;

      return { taxRefundCents, totalRefundCents };
    } catch (e) {
      Logger.error(e);
      return { ok: false };
    }
  };
};

export const getGiftCardTaxRefund = (incidentableId, incidentableType, refundCents) => {
  return async (dispatch) => {
    const response = await dispatch(
      makeApiRequest({
        type: IM_GIFT_CARD_TAX_REFUND,
        method: fetchGiftCardTaxRefund,
        params: { incidentableId, incidentableType, refundCents },
      })
    );

    const { giftCardRefundTaxCents } = response;
    const totalRefundCents = refundCents + giftCardRefundTaxCents;

    return { giftCardRefundTaxCents, totalRefundCents };
  };
};

export const submitCancellation = (formState) => {
  return async (dispatch) => {
    const response = await dispatch(
      makeApiRequest({
        type: IM_CANCEL,
        method: createCancellation,
        params: formState,
      })
    );

    let message = 'Order canceled!';
    if (response && response.error) {
      message = response.error;
    }

    dispatch(toggleSnackBar(true, message));
  };
};
