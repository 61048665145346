import {
  FVC_CHANGE_TYPES,
  FVC_CHANGE_TYPE_SET_QUANTITY,
  FVC_CHANGE_TYPE_TRANSFER_QUANTITY,
  FVC_CHANGE_TYPE_ADD_SUBTRACT_QUANTITY,
  FVC_CHANGE_TYPE_CHANGE_PRODUCTION_DAY,
} from 'lib/constants';

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { apiPost } from 'lib/homeChefApi';

import Button from '@mui/material/Button';
import CardHeader from '@mui/material/CardHeader';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { useAddAlert } from 'components/shared/AlertManager';
import getChangeRequestParams from 'lib/helpers/flexibleVolumeChanges/getChangeRequestParams';
import getQueuedChangeDescription from 'lib/helpers/flexibleVolumeChanges/getQueuedChangeDescription';

import ChangeRequestConfirmationDialog from '../ChangeRequestConfirmationDialog';
import SetQuantity from './SetQuantity';
import TransferQuantity from './TransferQuantity';
import AddSubtractQuantity from './AddSubtractQuantity';
import ChangeProductionDay from './ChangeProductionDay';

const FORM_COMPONENTS = {
  [FVC_CHANGE_TYPE_SET_QUANTITY]: SetQuantity,
  [FVC_CHANGE_TYPE_TRANSFER_QUANTITY]: TransferQuantity,
  [FVC_CHANGE_TYPE_ADD_SUBTRACT_QUANTITY]: AddSubtractQuantity,
  [FVC_CHANGE_TYPE_CHANGE_PRODUCTION_DAY]: ChangeProductionDay,
};

const ChangeRequestForm = ({ onClose }) => {
  const addAlert = useAddAlert();
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [changeType, setChangeType] = useState(FVC_CHANGE_TYPES[0].value);
  const [changeRequestData, setChangeRequestData] = useState(null);
  const [queuedChangeRequests, setQueuedChangeRequests] = useState([]);
  const [formKey, setFormKey] = useState(0);

  const FormComponent = FORM_COMPONENTS[changeType];

  const changeRequests = changeRequestData
    ? [...queuedChangeRequests, changeRequestData]
    : queuedChangeRequests;

  const submitActionText =
    changeRequests.length > 1 ? `Submit ${changeRequests.length} Changes` : 'Submit Change';

  const handleAddAnother = () => {
    setQueuedChangeRequests([...queuedChangeRequests, changeRequestData]);
    setChangeRequestData(null);
  };

  const handleGoBack = (request, i) => {
    const newArray = [...queuedChangeRequests];
    if (changeRequestData) {
      newArray.push(changeRequestData);
    }

    newArray.splice(i, 1);
    setQueuedChangeRequests(newArray);
    setChangeRequestData(request);
    setChangeType(request.changeType);
  };

  const handleClearForm = () => {
    setChangeRequestData(null);
  };

  const handleSubmit = async () => {
    const response = await apiPost(
      'internal/retail/flexible_volume_changes/submit_change_request',
      {
        change_requests: changeRequests.map((cr) => getChangeRequestParams(cr)),
      }
    );

    setConfirmDialogOpen(false);
    if (response.ok) {
      addAlert({
        severity: 'success',
        message: 'Processing volume change',
      });
      setFormKey(formKey + 1);
      onClose();
    } else {
      addAlert({
        severity: 'error',
        message: response.error,
      });
    }
  };

  return (
    <>
      <Stack key={formKey} direction="column" justifyContent="space-between" spacing={2}>
        <CardHeader
          title="Create Adjustment"
          action={
            <IconButton onClick={onClose} size="large">
              <CloseIcon />
            </IconButton>
          }
        />
        <TextField
          id="changeType"
          label="I Want To"
          value={changeType}
          variant="outlined"
          fullWidth
          select
        >
          {FVC_CHANGE_TYPES.map((ct) => (
            <MenuItem onClick={() => setChangeType(ct.value)} key={ct.value} value={ct.value}>
              {ct.name}
            </MenuItem>
          ))}
        </TextField>
        <FormComponent
          key={queuedChangeRequests.length}
          value={changeRequestData}
          onChange={setChangeRequestData}
        />
        <TextField
          id="queuedRequests"
          label={`View Queued Requests (${queuedChangeRequests.length})`}
          value="" // this prevents the tests from failing but is otherwise not needed
          disabled={queuedChangeRequests < 1}
          fullWidth
          select
        >
          {queuedChangeRequests.map((request, i) => (
            <MenuItem
              onClick={() => handleGoBack(request, i)}
              key={getQueuedChangeDescription(request)}
              value={request}
            >
              {getQueuedChangeDescription(request)}
            </MenuItem>
          ))}
        </TextField>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleAddAnother}
          fullWidth
          disabled={!changeRequestData}
        >
          Add Another
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setConfirmDialogOpen(true)}
          fullWidth
          disabled={!changeRequestData}
        >
          {submitActionText}
        </Button>
        <Button variant="contained" color="error" onClick={handleClearForm} fullWidth>
          Clear Form
        </Button>
      </Stack>
      <ChangeRequestConfirmationDialog
        open={confirmDialogOpen}
        changeRequests={changeRequests}
        onClose={() => setConfirmDialogOpen(false)}
        onSubmit={handleSubmit}
      />
    </>
  );
};

ChangeRequestForm.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default ChangeRequestForm;
