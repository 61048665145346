// Dependencies
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';

// Actions
import { postSwapIngredient } from 'redux/ingredientSwapper/swappableIngredient/actions';

// Components
import SwappableIngredientMeals from './SwappableIngredientMeals';

import styles from './styles';

const mapStateToProps = (state) => ({
  targetIngredient: state.ingredientSwapper.targetIngredient,
  swappableIngredient: state.ingredientSwapper.swappableIngredient,
});

const mapDispatchToProps = {
  onPostSwapIngredient: postSwapIngredient,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SwappableIngredientMeals));
