// Dependencies
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';

import { submitIncidents } from 'redux/incidentManager/incident/actions';
import { submitCancellation } from 'redux/incidentManager/incidentable/actions';

// Components
import Footer from './Footer';
import styles from './styles';

const isSubmitting = (state) => {
  const { incidentable } = state.incidentManager;
  const { incident } = state.incidentManager;
  return incidentable.submitting || incident.submitting;
};

const mapStateToProps = (state) => ({
  cancel: state.incidentManager.incident.cancel,
  submitting: isSubmitting(state),
});

const mapDispatchToProps = {
  onCancelOrder: submitCancellation,
  onSubmitIncidents: submitIncidents,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Footer));
