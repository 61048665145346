import { options, handleResponse } from 'lib/homeChefApi';
import { makeQueryParamURL } from 'lib/utils';
import { APP_API_URI } from 'lib/constants';

const IM_URL = `${APP_API_URI}/internal/customer_support/incident_manager`;

export const fetchIncidentable = async ({ incidentableId, incidentableType }) => {
  const queryUrl = makeQueryParamURL(`${IM_URL}/incidentable`, {
    incidentable_id: incidentableId,
    incidentable_type: incidentableType,
  });

  const response = await fetch(queryUrl, options);
  return handleResponse(response);
};

export const fetchTaxRefund = async ({ incidentableId, incidentableType, refundCents }) => {
  const queryUrl = makeQueryParamURL(`${IM_URL}/cash_tax_refund`, {
    incidentable_id: incidentableId,
    incidentable_type: incidentableType,
    cash_refund_cents: refundCents,
  });

  const response = await fetch(queryUrl, options);
  return handleResponse(response);
};

export const fetchGiftCardTaxRefund = async ({ incidentableId, incidentableType, refundCents }) => {
  const queryUrl = makeQueryParamURL(`${IM_URL}/gift_card_tax_refund`, {
    incidentable_id: incidentableId,
    incidentable_type: incidentableType,
    gift_card_refund_cents: refundCents,
  });

  const response = await fetch(queryUrl, options);
  return handleResponse(response);
};

export const createCancellation = async (incidentParams) => {
  const response = await fetch(`${IM_URL}/cancel_order`, {
    ...options,
    method: 'POST',
    body: JSON.stringify({
      employee_id: incidentParams.employeeId,
      incidentable_id: incidentParams.incidentableId,
      incidentable_type: incidentParams.incidentableType,
      reason: incidentParams.reason,
      other_description: incidentParams.reasonDescription,
    }),
  });

  return handleResponse(response);
};
