// Dependencies
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// Material
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Button from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check';
import Alert from '@mui/material/Alert';

// Components
import { Loading } from 'components/shared';

const MealRow = ({
  targetIngredient,
  swappableIngredientAssignment,
  onPostSwapIngredient,
  classes,
}) => {
  // Helpers
  const handleSwapIngredient = () => {
    onPostSwapIngredient(swappableIngredientAssignment, targetIngredient);
  };

  const failedSwap = swappableIngredientAssignment.swapStatus === 'failed swap';

  // Rendering
  const renderConceptIdLink = () => {
    return (
      <a
        href={swappableIngredientAssignment.adminLink}
        className={classes.link}
        target="_blank"
        rel="noopener noreferrer"
      >
        {swappableIngredientAssignment.culinaryVersionId}
      </a>
    );
  };

  const renderMenusList = () => {
    return (
      <ul className={classes.linkList}>
        {swappableIngredientAssignment.menus.map((menu) => {
          return (
            <li key={menu.link}>
              <a
                href={menu.link}
                className={classes.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                {menu.title}
              </a>
            </li>
          );
        })}
      </ul>
    );
  };

  const renderSwapButton = () => {
    return (
      <Button
        variant="contained"
        color="primary"
        disableElevation
        size="small"
        onClick={handleSwapIngredient}
        disabled={!targetIngredient.swapability}
      >
        Swap
      </Button>
    );
  };

  const renderSwapStatus = (swapStatus) => {
    switch (swapStatus) {
      case 'swapping':
        return <Loading size={20} topPadding={false} centered={false} />;
      case 'swapped':
        return <CheckIcon color="primary" />;
      case 'failed swap':
        // we want to give users the chance to retry swapping if there was an error
        return renderSwapButton();
      default:
        // unswapped, disabled or enabled button based on swapability
        return renderSwapButton();
    }
  };

  const renderErrorMessage = () => {
    if (failedSwap) {
      return (
        <TableRow>
          <TableCell
            colSpan={8}
            className={clsx(classes.errorlessRow, failedSwap && classes.errorRow)}
            align="center"
          >
            <Alert severity="error">
              An error occurred. Please retry the swap again, or make the ingredient change through
              the Admin meal page.
            </Alert>
          </TableCell>
        </TableRow>
      );
    }
    return null;
  };

  const titleCellClassName = clsx(classes.titleCell, failedSwap && classes.erroredCell);
  const cellClassName = clsx(classes.cell, failedSwap && classes.erroredCell);

  return (
    <>
      <TableRow>
        <TableCell className={titleCellClassName}>{swappableIngredientAssignment.mealId}</TableCell>
        <TableCell className={titleCellClassName}>{swappableIngredientAssignment.title}</TableCell>
        <TableCell className={cellClassName}>{renderConceptIdLink()}</TableCell>
        <TableCell className={cellClassName}>{renderMenusList()}</TableCell>
        <TableCell className={cellClassName}>{swappableIngredientAssignment.usageYield}</TableCell>
        <TableCell className={cellClassName}>{swappableIngredientAssignment.quantity}</TableCell>
        <TableCell className={cellClassName}>{swappableIngredientAssignment.measure}</TableCell>
        <TableCell className={cellClassName}>
          {renderSwapStatus(swappableIngredientAssignment.swapStatus)}
        </TableCell>
      </TableRow>
      {renderErrorMessage()}
    </>
  );
};

MealRow.propTypes = {
  targetIngredient: PropTypes.shape({
    brandName: PropTypes.string,
    displayName: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string,
    standardUnit: PropTypes.string,
    state: PropTypes.string,
    usageYield: PropTypes.string,
    adminIngredientLink: PropTypes.string,
    swapability: PropTypes.bool,
  }),
  swappableIngredientAssignment: PropTypes.shape({
    id: PropTypes.number,
    mealId: PropTypes.number,
    title: PropTypes.string,
    culinaryVersionId: PropTypes.string,
    adminLink: PropTypes.string,
    menus: PropTypes.arrayOf(PropTypes.object),
    usageYield: PropTypes.number,
    measure: PropTypes.string,
    quantity: PropTypes.number,
    swapStatus: PropTypes.string,
  }).isRequired,
  onPostSwapIngredient: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

MealRow.defaultProps = {
  targetIngredient: null,
};

export default MealRow;
