import { options, handleResponse } from 'lib/homeChefApi';
import { APP_API_URI } from 'lib/constants';

const MG_URL = `${APP_API_URI}/internal/culinary/menugrid`;

export const getSingleMenuData = async ({ displayGroup, menuId }) => {
  const url = `${MG_URL}/menu_data?display_group=${displayGroup}&menu_id=${menuId}`;

  const response = await fetch(url, options);
  return handleResponse(response);
};
