// Dependencies
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';

// Actions
import { getFormOptions } from 'redux/incidentManager/incident/actions';
import {
  getTaxRefund,
  getGiftCardTaxRefund,
  submitCancellation,
} from 'redux/incidentManager/incidentable/actions';

// Components
import IncidentForm from './IncidentForm';

import styles from './styles';

const isSubmitting = (state) => {
  const { incident, incidentable } = state.incidentManager;
  return incident.submitting || incidentable.submitting;
};

const mapStateToProps = (state) => ({
  cancel: state.incidentManager.incident.cancel,
  formOptions: state.incidentManager.incident.formOptions,
  incidentable: state.incidentManager.incidentable.incidentable,
  submitting: isSubmitting(state),
});

const mapDispatchToProps = {
  onCancelOrder: submitCancellation,
  onGetFormOptions: getFormOptions,
  onGetTaxRefund: getTaxRefund,
  onGetGiftCardTaxRefund: getGiftCardTaxRefund,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(IncidentForm));
