import React from 'react';

import { Button, Card, CardContent, CardMedia, Grid, IconButton, Typography } from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';

import { calculateFileSize } from 'lib/helpers/behavioralDiscountTool/bannerHelper';

const BannerImageUploader = ({
  classes,
  currentPhoto,
  setCurrentPhotoCallback,
  selectedFileName,
  selectedTemporaryImage,
  setErrorState,
  setFilenameCallback,
  setPhotoFormStateCallback,
  setTemporaryImageCallback,
}) => {
  const checkFileSize = (file) => Number(calculateFileSize(file.size)) <= 150;

  const imgChecker = (file) => {
    const extension = file.name.split('.').pop().toLowerCase();
    const bool = extension === 'jpg' || extension === 'png' || checkFileSize(file);
    setErrorState(!bool);
    return bool;
  };

  const handleImgUpload = async (e) => {
    if (!e.target.files) return;

    const selectedImage = e.target.files[0];

    if (imgChecker(selectedImage)) {
      setFilenameCallback(selectedImage.name);
      setTemporaryImageCallback(URL.createObjectURL(selectedImage));
      setPhotoFormStateCallback(selectedImage);
    }
  };

  const removeImage = () => {
    setFilenameCallback(null);
    setTemporaryImageCallback(null);

    const newCurrentPhotoState = currentPhoto;
    newCurrentPhotoState.deleted = true;
    setCurrentPhotoCallback(newCurrentPhotoState);
  };

  const UploadImage = (text = 'UPLOAD AN IMAGE') => {
    return (
      <Button component="label" variant="outlined">
        {text}
        <input className={classes.input} type="file" accept="image/*" onChange={handleImgUpload} />
      </Button>
    );
  };

  const RemoveImage = () => {
    return (
      <Card className={classes.imgContainer}>
        <CardContent>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <CardMedia
                component="img"
                height="75"
                image={selectedTemporaryImage}
                className={classes.img}
              />
            </Grid>
            <Grid item xs={12} sm container>
              <Grid item>
                <Typography gutterBottom component="div" className={classes.imgFileName}>
                  {selectedFileName}
                </Typography>
              </Grid>
              <Grid item xs={12} sm container justifyContent="flex-end" gap={2}>
                {UploadImage('Replace')}
                <IconButton onClick={() => removeImage()} size="small">
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  };

  const imageComponent = selectedTemporaryImage ? RemoveImage : UploadImage;

  return imageComponent();
};

export default BannerImageUploader;
