import React from 'react';
import {
  ArrayField,
  DatagridConfigurable,
  ExportButton,
  List,
  SearchInput,
  SelectColumnsButton,
  SelectInput,
  SimpleList,
  TextField,
  TextInput,
  TopToolbar,
  WithRecord,
} from 'react-admin';
import { useQuery } from 'react-query';
import { GET } from 'lib/api';

import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import { Link } from 'react-router-dom';
import { APP_API_URI } from 'lib/constants';

const ListActions = () => (
  <TopToolbar>
    <Button
      color="primary"
      component={Link}
      to={{
        pathname: '/hub/compound_ingredients',
        search: 'filter={}',
      }}
    >
      Clear Filters
    </Button>
    <SelectColumnsButton />
    <ExportButton />
  </TopToolbar>
);

const getFilters = async () => {
  const res = await GET(`${APP_API_URI}/internal/hub/filter_options`).then((response) => {
    return response.data.filterOptions;
  });
  return res;
};

const compoundIngredientFilters = (stateFilters, startDate, endDate) => {
  return [
    <TextInput key="startDate" source={startDate} alwaysOn readOnly />,
    <TextInput key="endDate" source={endDate} alwaysOn readOnly />,
    <SearchInput key="search" source="q" placeholder="Search" alwaysOn />,
    <SelectInput key="state" source="state" alwaysOn choices={stateFilters} />,
  ];
};

const CompoundIngredientDashboard = () => {
  const { data } = useQuery('filters', getFilters);
  const startDate = data ? data.startShipDate : '';
  const endDate = data ? data.endShipDate : '';
  const stateFilters = data
    ? data.states.map((state) => {
        return { id: state, name: state };
      })
    : [];

  return (
    <Container>
      <Stack spacing={2} alignItems="center">
        <>
          <h1>State cards go here</h1>
        </>
        <>
          <h1>Bar Graph goes here</h1>
        </>
        <>
          <List
            actions={<ListActions />}
            filters={compoundIngredientFilters(stateFilters, startDate, endDate)}
          >
            <DatagridConfigurable>
              <TextField source="id" />
              <TextField source="name" />
              <TextField source="displayName" />
              <TextField source="standardUnit" />
              <TextField source="state" />
              <ArrayField source="yieldsByFacility" reference="yieldsByFacility">
                <SimpleList
                  primaryText={(record) => record.packingFacility}
                  secondaryText={(record) => record.yieldNumber}
                  key={(record) => record.id}
                />
              </ArrayField>
              <TextField source="ingredientClassificationName" />
              <ArrayField source="allergens" reference="allergens" label="Allergens">
                <SimpleList
                  empty={<p>No allergens to display</p>}
                  primaryText={(record) => record}
                />
              </ArrayField>
              <ArrayField source="overOrdersByFacility" reference="overOrdersByFacility">
                <SimpleList
                  primaryText={(record) => record.packingFacility}
                  secondaryText={(record) => record.overOrder}
                  key={(record) => record.id}
                />
              </ArrayField>
              <TextField source="outputYield" />
              <TextField source="outputPerRunMetric" />
              <TextField source="minOutputPerRun" />
              <TextField source="maxOutputPerRun" />
              <WithRecord
                render={(record) => (
                  <Button
                    color="primary"
                    component={Link}
                    to={`${APP_API_URI}/admin/compound_ingredients/${record.id}`}
                  >
                    View CI
                  </Button>
                )}
              />
            </DatagridConfigurable>
          </List>
        </>
      </Stack>
    </Container>
  );
};

export default CompoundIngredientDashboard;
