import { options, handleResponse } from 'lib/homeChefApi';
import { makeQueryParamURL } from 'lib/utils';
import { APP_API_URI } from 'lib/constants';

export const fetchIngredient = async ({ ingredientId }) => {
  const response = await fetch(
    makeQueryParamURL(`${APP_API_URI}/internal/culinary/ingredient_swapper/ingredient`, {
      ingredient_id: ingredientId,
    }),
    options
  );

  return handleResponse(response);
};

export const swapIngredient = async ({ swappableIngredientAssignment, targetIngredient }) => {
  const url = `${APP_API_URI}/internal/culinary/ingredient_swapper/swap_ingredient`;

  const response = await fetch(url, {
    ...options,
    method: 'POST',
    body: JSON.stringify({
      swappable_ingredient_assignment_id: swappableIngredientAssignment.id,
      target_ingredient_id: targetIngredient.id,
      display_name: targetIngredient.displayName,
      usage_yield: targetIngredient.usageYield,
      quantity: targetIngredient.quantity,
      measure: targetIngredient.measure,
      portion_method: targetIngredient.portionMethod,
      ingredient_packaging_id: targetIngredient.ingredientPackagingId,
    }),
  });

  return handleResponse(response);
};
