import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';

import { cleanArray } from 'lib/utils';

import styles from './styles';
import IncidentGrid from './IncidentGrid';

const mapStateToProps = (state) => ({
  error: state.incidentManager.incident.error || state.incidentManager.incidentable.error,
  errorMessages: cleanArray([
    state.incidentManager.incident.errorMessage,
    state.incidentManager.incidentable.errorMessage,
  ]),
  success: state.incidentManager.incident.success,
  successMessages: cleanArray([
    state.incidentManager.incident.successMessage,
    state.incidentManager.incidentable.successMessage,
  ]),
  incidents: state.incidentManager.incident.incidents,
});

export default connect(mapStateToProps)(withStyles(styles)(IncidentGrid));
