import { options, handleResponse } from 'lib/homeChefApi';
import { makeQueryParamURL } from 'lib/utils';
import { mapKeysDeep } from 'lib/helpers/objects';
import { forEach, snakeCase } from 'lodash';
import { APP_API_URI } from 'lib/constants';

export const MEF_URL = `${APP_API_URI}/internal/culinary/meal_edit_form`;

export const fetchMeal = async ({ mealId }) => {
  const queryUrl = makeQueryParamURL(`${MEF_URL}/meal`, { meal_id: mealId });

  const response = await fetch(queryUrl, options);

  return handleResponse(response);
};

export const updateMeal = async ({ mealId, mealParams }) => {
  const queryUrl = makeQueryParamURL(`${MEF_URL}/meal`, { meal_id: mealId });

  const transformedMealParams = mapKeysDeep(mealParams, (_value, key) => {
    return snakeCase(key);
  });

  const response = await fetch(queryUrl, {
    ...options,
    method: 'POST',
    body: JSON.stringify(transformedMealParams),
  });

  return handleResponse(response);
};

export const updateMealPhotos = async ({ mealId, mealParams }) => {
  const queryUrl = makeQueryParamURL(`${MEF_URL}/meal`, { meal_id: mealId });

  const images = [];
  const formData = new FormData();

  forEach(mealParams.photos, (photo) => {
    images.push({
      id: photo.id,
      category: photo.category,
      index: photo.index,
      deleted: photo.deleted,
    });

    if (photo.newImage) {
      const filenameWithIndex = `i${photo.index}-${photo.newImage.name}`;
      formData.append('photo_files[]', photo.newImage, filenameWithIndex);
    }
  });

  formData.append('photos_attributes', JSON.stringify(images));

  const response = await fetch(queryUrl, {
    mode: options.mode,
    credentials: options.credentials,
    method: 'POST',
    body: formData,
  });

  return handleResponse(response);
};

export const updateMealRecipeStepsTab = async ({ mealId, mealParams }) => {
  const queryUrl = makeQueryParamURL(`${MEF_URL}/meal`, { meal_id: mealId });

  const transformedMealParams = mapKeysDeep(mealParams, (_value, key) => {
    return snakeCase(key);
  });

  const formData = new FormData();

  if (mealParams.pdf) {
    formData.append('pdf', mealParams.pdf);
  }
  formData.append('utensil_assignments_attributes', JSON.stringify(transformedMealParams.utensils));
  formData.append(
    'before_you_cook_instruction_assignments_attributes',
    JSON.stringify(transformedMealParams.before_you_cook_instructions)
  );
  formData.append(
    'customize_recipe_attributes',
    JSON.stringify(transformedMealParams.customize_it_step)
  );

  const recipeSteps = [];

  forEach(mealParams.recipeSteps, (recipeStep) => {
    recipeSteps.push({
      id: recipeStep.id,
      deleted: recipeStep.deleted,
      description: recipeStep.description,
      image: recipeStep.image,
      index: recipeStep.index,
      plated: recipeStep.plated,
      step: recipeStep.step,
      title: recipeStep.title,
    });

    if (recipeStep.newImage) {
      const filenameWithIndex = `i${recipeStep.index}-${recipeStep.newImage.name}`;
      formData.append('recipe_image_files[]', recipeStep.newImage, filenameWithIndex);
    }
  });

  formData.append('recipes_attributes', JSON.stringify(recipeSteps));

  const response = await fetch(queryUrl, {
    mode: options.mode,
    credentials: options.credentials,
    method: 'POST',
    body: formData,
  });

  return handleResponse(response);
};
