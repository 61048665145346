import React from 'react';
import PropTypes from 'prop-types';

// Components
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';

const DeliveryInfo = ({ incidentable, classes }) => {
  return (
    <Paper className={classes.root} variant="outlined">
      {incidentable.recurringDiscount && (
        <Chip className={classes.chip} label={incidentable.recurringDiscount} />
      )}
      <div className={classes.header}>Delivery Info</div>
      <DeliveryInfoItemWithLink
        title="Email"
        subtitle={incidentable.email}
        classes={classes}
        link={incidentable.adminUserLink}
        target="_parent"
      />
      <DeliveryInfoItem title="Basket ID" subtitle={incidentable.id.toString()} classes={classes} />
      <DeliveryInfoItemWithRedText
        title="Delivery Date"
        subtitle={incidentable.deliveryDate}
        classes={classes}
        displayRedText={incidentable.delayed}
        redText={incidentable.delayType}
      />
      {incidentable.shipmentSummary.map((box) => {
        return (
          box.trackingNumber && (
            <DeliveryInfoItemWithLink
              title="Tracking Number"
              subtitle={box.trackingNumber}
              link={box.trackingLink}
              target="_blank"
              rel="noopener noreferrer"
              classes={classes}
              key={box.trackingNumber}
            />
          )
        );
      })}
      <DeliveryInfoItem title="Meal Plan" subtitle={incidentable.mealPlan} classes={classes} />
    </Paper>
  );
};

const DeliveryInfoItem = ({ title, subtitle, classes }) => {
  return (
    <div className={classes.item}>
      <div className={classes.title}>{title}</div>
      <div className={classes.subtitle}>{subtitle}</div>
    </div>
  );
};

const DeliveryInfoItemWithLink = ({ title, subtitle, link, target, rel, classes }) => {
  return (
    <div className={classes.item}>
      <div className={classes.title}>{title}</div>
      <div className={classes.subtitle}>
        <a href={link} target={target} rel={rel} className={classes.link}>
          {subtitle}
        </a>
      </div>
    </div>
  );
};
const DeliveryInfoItemWithRedText = ({ title, subtitle, displayRedText, redText, classes }) => {
  return (
    <div className={classes.item}>
      <div className={classes.title}>{title}</div>
      <div className={classes.subtitle}>
        {subtitle}
        {displayRedText && <div className={classes.redText}>{redText}</div>}
      </div>
    </div>
  );
};

DeliveryInfo.propTypes = {
  incidentable: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

DeliveryInfoItem.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
};

DeliveryInfoItemWithLink.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  target: PropTypes.string,
  rel: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

DeliveryInfoItemWithRedText.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  displayRedText: PropTypes.bool.isRequired,
  redText: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
};

DeliveryInfoItemWithLink.defaultProps = {
  target: '',
  rel: '',
};

export default DeliveryInfo;
