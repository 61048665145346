import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Container, Divider, MenuItem, TextField, Typography } from '@mui/material';

import {
  defaultTemplates,
  promotionalTemplates,
} from 'lib/helpers/behavioralDiscountTool/bannerHelper';
import BannerImageUploader from './BannerImageUploader';

const BannerSelector = ({
  classes,
  currentPhoto,
  setCurrentPhotoCallback,
  selectedBannerTheme,
  selectedDashboardTagText,
  selectedDiscountDescriptionText,
  selectedDiscountPromoText,
  selectedFileName,
  selectedTemporaryImage,
  setBannerThemeCallback,
  setDashboardTagTextCallback,
  setDiscountDescriptionTextCallback,
  setDiscountPromoTextCallback,
  setFilenameCallback,
  setPhotoFormStateCallback,
  setTemporaryImageCallback,
}) => {
  const [errorState, setErrorState] = useState(false);
  const imgHelperText = 'Image should be a 192 x 276 .png or .jpg and under 150kb.';
  const defaultThemes = Object.entries(defaultTemplates);
  const promotionalThemes = Object.entries(promotionalTemplates);

  const onThemeChange = (theme) => {
    setBannerThemeCallback(theme);
  };

  return (
    <Container disableGutters maxWidth={false}>
      <TextField
        fullWidth
        label="Promotion Text"
        variant="outlined"
        value={selectedDiscountPromoText}
        inputProps={{ maxLength: 35 }}
        helperText="Max character length: 35"
        onChange={(e) => setDiscountPromoTextCallback(e.target.value)}
        className={classes.containers}
      />
      <TextField
        fullWidth
        label="Description Text"
        variant="outlined"
        value={selectedDiscountDescriptionText}
        inputProps={{ maxLength: 56 }}
        helperText="Include an * at the end for disclosures. Max character length: 56"
        onChange={(e) => setDiscountDescriptionTextCallback(e.target.value)}
        className={classes.containers}
      />
      <TextField
        fullWidth
        select
        label="Select banner colors"
        margin="normal"
        size="large"
        variant="outlined"
        className={classes.bannerMenu}
        value={selectedBannerTheme}
        onChange={(e) => onThemeChange(e.target.value)}
      >
        <span className={classes.themeHeading}>Default Themes</span>
        {defaultThemes.map(([themeName, theme]) => (
          <MenuItem key={themeName} value={themeName}>
            <div className={classes.menuSwatches}>
              <img src={theme.image} alt={`banner-theme-${themeName}`} />
              <span className={classes.bannerOptions}>{theme.descriptor}</span>
            </div>
          </MenuItem>
        ))}
        <span className={classes.themeHeading}>Promotional Themes</span>
        {promotionalThemes.map(([themeName, theme]) => (
          <MenuItem key={themeName} value={themeName}>
            <div className={classes.menuSwatches}>
              <img src={theme.image} alt={`banner-theme-${themeName}`} />
              <span className={classes.bannerOptions}>{theme.descriptor}</span>
            </div>
          </MenuItem>
        ))}
      </TextField>
      <div className={classes.containers}>
        <BannerImageUploader
          classes={classes}
          currentPhoto={currentPhoto}
          setCurrentPhotoCallback={setCurrentPhotoCallback}
          selectedFileName={selectedFileName}
          selectedTemporaryImage={selectedTemporaryImage}
          setErrorState={setErrorState}
          setFilenameCallback={setFilenameCallback}
          setPhotoFormStateCallback={setPhotoFormStateCallback}
          setTemporaryImageCallback={setTemporaryImageCallback}
        />
        <Typography className={errorState ? classes.errorText : classes.imgHelperText}>
          {imgHelperText}
        </Typography>
      </div>
      <Divider className={classes.divider} />
      <Typography className={classes.tagHeader}>Upcoming Orders Offer Tag</Typography>
      <TextField
        fullWidth
        label="Offer Tag Copy"
        variant="outlined"
        value={selectedDashboardTagText}
        inputProps={{ maxLength: 20 }}
        helperText="max character length: 20"
        onChange={(e) => setDashboardTagTextCallback(e.target.value)}
        className={classes.containers}
      />
    </Container>
  );
};

BannerSelector.propTypes = {
  classes: PropTypes.object.isRequired,
  currentPhoto: PropTypes.object,
  setCurrentPhotoCallback: PropTypes.func.isRequired,
  selectedBannerTheme: PropTypes.string,
  selectedDashboardTagText: PropTypes.string,
  selectedDiscountDescriptionText: PropTypes.string,
  selectedDiscountPromoText: PropTypes.string,
  selectedFileName: PropTypes.string,
  selectedTemporaryImage: PropTypes.string,
  setBannerThemeCallback: PropTypes.func.isRequired,
  setDashboardTagTextCallback: PropTypes.func.isRequired,
  setFilenameCallback: PropTypes.func.isRequired,
  setPhotoFormStateCallback: PropTypes.func.isRequired,
  setDiscountDescriptionTextCallback: PropTypes.func.isRequired,
  setDiscountPromoTextCallback: PropTypes.func.isRequired,
  setTemporaryImageCallback: PropTypes.func.isRequired,
};

BannerSelector.defaultProps = {
  currentPhoto: {},
  selectedBannerTheme: 'default',
  selectedDashboardTagText: '',
  selectedDiscountDescriptionText: '',
  selectedDiscountPromoText: '',
  selectedFileName: '',
  selectedTemporaryImage: '',
};

export default BannerSelector;
