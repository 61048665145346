import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { startCase } from 'lodash';

import { Grid, Paper, Stack, Chip } from '@mui/material';
import { FeatureFlag } from 'components/shared';

import StateActionButton from './StateActionButton';
import Dropdown from '../../shared/Dropdown';
import SectionHeader from '../shared/SectionHeader';
import TextBox from '../../shared/TextBox';

const BasicAttributes = ({ classes, formState, handleFormState, dirty }) => {
  const { formOptions } = useSelector((state) => state.compoundIngredientRecipeForm.formOptions);

  const selectStates = useMemo(() => {
    return formOptions.states.map((state) => ({
      label: state,
      value: state,
    }));
  }, [formOptions.states]);

  const selectIngredientClassifications = useMemo(() => {
    return formOptions.ingredientClassifications.map((classification) => ({
      label: classification.path,
      value: classification.id,
    }));
  }, [formOptions.ingredientClassifications]);

  return (
    <Paper className={classes.paperWithBottomMargin}>
      <SectionHeader text="Basic Attributes" />
      <Grid container direction="column" spacing={2}>
        <Grid item xs={6}>
          <TextBox
            fullWidth
            label="Name"
            name="name"
            onChange={handleFormState}
            required
            value={formState.name}
          />
        </Grid>
        <Grid item xs={6}>
          <Dropdown
            label="Ingredient Classification"
            name="ingredientClassificationId"
            onChange={handleFormState}
            options={selectIngredientClassifications}
            value={formState.ingredientClassificationId}
          />
        </Grid>
        <Grid item xs={6}>
          <Grid container direction="column" spacing={0.5}>
            <FeatureFlag flag="enable_compound_ingredient_management">
              <Grid item>
                <span className={classes.labelText}>Current State</span>
              </Grid>
              <Grid item>
                <Stack direction="row" spacing={0.5}>
                  {formOptions.states.map((state) => {
                    return (
                      <Chip
                        color={formState.state === state ? 'primary' : 'default'}
                        label={`${startCase(state)}`}
                        key={state}
                      />
                    );
                  })}
                </Stack>
              </Grid>
              <Grid item>
                <span className={classes.labelText}>Actions</span>
              </Grid>
              <Grid item>
                <Stack direction="row" spacing={0.5}>
                  {formState.events?.map((event) => {
                    return (
                      <StateActionButton
                        eventType={event}
                        compoundIngredientId={formState.id}
                        disabled={dirty}
                        key={event}
                      />
                    );
                  })}
                </Stack>
              </Grid>
            </FeatureFlag>
            <FeatureFlag flag="enable_compound_ingredient_management" whenOff>
              <Dropdown
                label="State"
                name="state"
                onChange={handleFormState}
                options={selectStates}
                required
                value={formState.state}
              />
            </FeatureFlag>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

BasicAttributes.propTypes = {
  classes: PropTypes.object.isRequired,
  formState: PropTypes.object.isRequired,
  handleFormState: PropTypes.func.isRequired,
  dirty: PropTypes.bool,
};

BasicAttributes.defaultProps = {
  dirty: false,
};

export default BasicAttributes;
