import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { find } from 'lodash';

import Dropdown from '../../shared/Dropdown';

const MealType = ({ formState, handleFormState }) => {
  const { formOptions } = useSelector((state) => state.mealEditForm.formOptions);

  const selectMealTypes = useMemo(() => {
    const mealTypesForProductType = (productTypeId) => {
      const selectedProductType = find(formOptions.productTypes, (productType) => {
        return productType.id === productTypeId;
      });
      return selectedProductType ? selectedProductType.mealTypes : [];
    };

    return mealTypesForProductType(formState.productTypeId).map((mealType) => ({
      label: mealType.name,
      value: mealType.id,
      key: mealType.id,
    }));
  }, [formOptions.productTypes, formState.productTypeId]);

  return (
    <Dropdown
      required
      label="Meal Type"
      name="mealTypeId"
      onChange={handleFormState}
      value={formState.mealTypeId}
      options={selectMealTypes}
    />
  );
};

MealType.propTypes = {
  formState: PropTypes.object.isRequired,
  handleFormState: PropTypes.func.isRequired,
};

export default MealType;
