import { options, handleResponse } from 'lib/homeChefApi';
import { makeQueryParamURL } from 'lib/utils';
import { APP_API_URI } from 'lib/constants';

export const fetchAllMenus = async () => {
  const response = await fetch(
    makeQueryParamURL(`${APP_API_URI}/internal/retail/menu_management/get_all_menus`),
    options
  );
  return handleResponse(response);
};

export const createNewMenu = async () => {
  const url = `${APP_API_URI}/internal/retail/menu_management/create_menu`;

  const response = await fetch(url, {
    ...options,
    method: 'POST',
  });

  return handleResponse(response);
};
