// Dependencies
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

// Material Components
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

// Components
import { Loading } from 'components/shared';
import AppHeader from '../shared/AppHeader';
import BackToAdmin from '../shared/BackToAdmin';
import Header from './Header';
import TargetIngredientSelector from './TargetIngredientSelector';
import SwappableIngredientMeals from './SwappableIngredientMeals';
import SwappableIdInput from './SwappableIdInput';

const IngredientSwapper = ({ swappableIngredient, onGetSwappableIngredient, classes }) => {
  // Helper methods
  const { swappableIngredientId } = useParams();

  // Lifecycle methods
  useEffect(() => {
    if (swappableIngredientId !== undefined) {
      onGetSwappableIngredient(swappableIngredientId);
    }
  }, [onGetSwappableIngredient, swappableIngredientId]);

  // Rendering
  if (swappableIngredient.fetching) {
    return <Loading />;
  }

  // `swappableIngredientId === undefined` is equal to accessing the `ingredient-swapper/` url
  // without entering a `:swappableIngredientId` param
  if (swappableIngredientId === undefined || swappableIngredient.error) {
    return (
      <>
        <AppHeader text="Swap Ingredients" />
        <SwappableIdInput />;
      </>
    );
  }

  return (
    <>
      <AppHeader text="Swap Ingredients" />
      <div className={classes.root}>
        {swappableIngredient.loaded && (
          <Container className={classes.container}>
            <BackToAdmin url={swappableIngredient.adminIngredientLink} text="Back to Admin" />
            <Header swappableIngredient={swappableIngredient} />
          </Container>
        )}
      </div>
      <Paper variant="outlined" className={classes.paper}>
        <Container className={classes.container}>
          <Grid container>
            <Grid item xs={3} className={classes.ingredientSelectorBox}>
              <TargetIngredientSelector />
            </Grid>
            <Grid item xs={9}>
              {swappableIngredient.loaded && <SwappableIngredientMeals />}
            </Grid>
          </Grid>
        </Container>
      </Paper>
    </>
  );
};

IngredientSwapper.propTypes = {
  swappableIngredient: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    brandName: PropTypes.string,
    displayName: PropTypes.string,
    standardUnit: PropTypes.string,
    state: PropTypes.string,
    usageYield: PropTypes.string,
    adminIngredientLink: PropTypes.string,
    ingredientAssignments: PropTypes.arrayOf(PropTypes.object),
    fetching: PropTypes.bool,
    loaded: PropTypes.bool,
    error: PropTypes.bool,
  }),
  onGetSwappableIngredient: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

IngredientSwapper.defaultProps = {
  swappableIngredient: null,
};

export default IngredientSwapper;
