import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { apiPost } from 'lib/homeChefApi';

import NetsuiteAutocomplete from 'components/shared/NetsuiteAutocomplete';
import { NETSUITE_TYPE_LINE_OF_BUSINESS } from 'lib/constants';

import { Button, Stack, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const AddCategoryForm = ({ classes, getData, handleClose, setJobId, setSnackState }) => {
  const [categoryName, setCategoryName] = useState('');
  const [categoryDescription, setCategoryDescription] = useState('');
  const [categoryInternalId, setCategoryInternalId] = useState(null);

  const closeDrawer = () => {
    setCategoryName('');
    setCategoryDescription('');
    setCategoryInternalId(null);
    handleClose();
  };

  const submitForm = async () => {
    const response = await apiPost('internal/retail/category/save_category', {
      name: categoryName,
      description: categoryDescription,
      netsuite_internal_id: categoryInternalId,
    });

    if (response.ok) {
      setJobId(response.retailCategory.updateAssortmentsJobId);
      setSnackState({
        message: `Creating ${categoryName} and related assortments - Check back later!`,
        severity: 'success',
      });
      closeDrawer();
      getData();
    } else {
      setSnackState({
        message: `Error creating ${categoryName} - try again or contact tech for help`,
        severity: 'error',
      });
    }
  };

  return (
    <Stack justifyContent="space-between" spacing={3}>
      <Typography variant="h5" classes={classes.titleBar}>
        Add New Product Line
        <Button onClick={closeDrawer}>
          <CloseIcon />
        </Button>
      </Typography>
      <TextField
        label="Product Line Info"
        variant="outlined"
        value={categoryName}
        onChange={(e) => setCategoryName(e.target.value)}
      />
      <TextField
        label="Description (optional)"
        variant="outlined"
        value={categoryDescription}
        onChange={(e) => setCategoryDescription(e.target.value)}
      />
      <NetsuiteAutocomplete
        type={NETSUITE_TYPE_LINE_OF_BUSINESS}
        value={categoryInternalId}
        onChange={(id) => setCategoryInternalId(id)}
      />
      <Button
        variant="contained"
        onClick={submitForm}
        disabled={categoryInternalId === null || categoryName.length === 0}
      >
        Save
      </Button>
    </Stack>
  );
};

AddCategoryForm.propTypes = {
  classes: PropTypes.object.isRequired,
  getData: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  setJobId: PropTypes.func.isRequired,
  setSnackState: PropTypes.func.isRequired,
};

export default AddCategoryForm;
