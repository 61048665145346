import { MEDIUM_GREY } from 'lib/constants';

export default {
  alignTop: {
    marginTop: -8,
  },
  claimsContainer: {
    borderTop: 'solid 1px #D8DADC',
    paddingTop: 24,
    marginTop: 24,
  },
  container: {
    padding: 24,
  },
  labelText: {
    color: MEDIUM_GREY,
    fontSize: 10,
    paddingLeft: 12,
  },
  outputYieldGridItem: { marginTop: 8 },
  paddingRight: {
    paddingRight: 16,
  },
  paper: {
    padding: 16,
  },
  paperWithBottomMargin: {
    padding: 16,
    marginBottom: 16,
  },
  searchBox: {
    marginTop: 24,
  },
  text: {
    fontSize: 12,
  },
};
