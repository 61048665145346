export default {
  bannerPreviewContainer: {
    backgroundColor: '#fef9f5',
    borderRadius: '0.5rem',
    width: '100%',
    paddingTop: '1em',
    paddingBottom: '1em',
    paddingLeft: '1.5em',
    paddingRight: '1.5em',
  },
  bannerOuterContainer: {
    borderRadius: '0.5rem',
    width: '100%',
  },
  bannerInnerContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  bannerContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 0%',
    alignItems: 'flex-start',
    gap: '0.125rem',
    paddingTop: '0.75rem',
    paddingBottom: '0.75rem',
    paddingLeft: '1rem',
  },
  bannerImageContainer: {
    width: '6rem',
    position: 'relative',
    overflow: 'hidden',
  },
  bannerImage: {
    width: '100%',
    height: '100% !important',
    position: 'absolute',
    borderTopRightRadius: '0.5rem',
    borderBottomRightRadius: '0.5rem',
    objectFit: 'cover',
  },
  bannerLabelContainer: {
    textTransform: 'uppercase',
    paddingTop: '0.125rem',
    paddingBottom: '0.125rem',
    paddingLeft: '0.25rem',
    paddingRight: '0.25rem',
    borderRadius: '0.25rem',
  },
  bannerLabelContentContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '0.25rem',
  },
  bannerLabelIcon: {
    width: '0.75rem',
    height: '0.75rem',
  },
  h6: {
    fontSize: '1.125rem',
    lineHeight: '1.3333',
    fontWeight: '650',
  },
  h7: {
    fontWeight: '400',
    fontSize: '0.8125rem',
    lineHeight: '1.2307692308',
  },
};
