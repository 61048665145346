import Logger from 'lib/Logger';
import { makeQueryParamURL } from 'lib/utils';
import { APP_API_URI } from 'lib/constants';

export const options = {
  mode: 'cors',
  credentials: 'include', // Instructs the browser to send authentication cookies with the cross-origin request
  headers: new Headers({
    'Content-Type': 'application/json',
    accept: 'application/json',
  }),
};

export const optionsWithoutContentType = {
  mode: 'cors',
  credentials: 'include', // Instructs the browser to send authentication cookies with the cross-origin request
  headers: new Headers({
    accept: 'application/json',
  }),
};

/**
 * Because Mealhand API sometimes returns Content-Type application/html on errors
 * and sometimes returns empty body with application/json on success or error
 */
const readResponse = async (response) => {
  try {
    return await response.json();
  } catch (e) {
    Logger.error(e);
    return null;
  }
};

const redirectToAdminLogin = () => {
  window.location = `${APP_API_URI}/`;
};

export const handleResponse = async (response, transformResponse = (x) => x) => {
  let responseBody = await readResponse(response);
  if (response.ok) responseBody = transformResponse(responseBody);
  if (response.status === 401 || response.status === 403) {
    redirectToAdminLogin();
  }
  return {
    ok: response.ok,
    statusText: response.statusText,
    status: response.status,
    url: response.url,
    error: response.ok ? undefined : (responseBody && responseBody.error) || response.statusText,
    ...responseBody,
  };
};

const apiFetch = async (url, requestOptions = {}) => {
  try {
    const response = await fetch(url, {
      ...options,
      ...requestOptions,
    });
    if (!response.ok) {
      Logger.error(`Url: ${url}, Error: ${JSON.stringify(response)}`, {
        url,
        requestOptions,
      });
    }
    return handleResponse(response);
  } catch (error) {
    Logger.error(`Error: ${JSON.stringify(error)}`, { url, requestOptions });
    return {
      ok: false,
      error: error.message,
    };
  }
};

export const apiGet = async (path, params = {}) =>
  apiFetch(makeQueryParamURL(`${APP_API_URI}/${path}`, params));

export const apiPost = async (path, params = {}) =>
  apiFetch(`${APP_API_URI}/${path}`, {
    method: 'POST',
    body: JSON.stringify(params),
  });

export const apiPostFile = async (path, file) => {
  const formData = new FormData();
  formData.append('file', file);
  return apiFetch(`${APP_API_URI}/${path}`, {
    headers: new Headers({
      accept: 'application/json',
    }),
    method: 'POST',
    body: formData,
  });
};
