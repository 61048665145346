import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { apiGet } from 'lib/homeChefApi';

import RetailPage from 'components/Retail/Page';

import { Button, Drawer } from '@mui/material';
import FulfillerTable from './FulfillerTable';
import AddFulfillerForm from './ManageFulfillerForms/AddFulfillerForm';
import EditFulfillerForm from './ManageFulfillerForms/EditFulfillerForm';

const Fulfillers = ({ classes }) => {
  const [fulfillers, setFulfillers] = useState([]);
  const [workingFulfiller, setWorkingFulfiller] = useState({});
  const [drawerOpen, setDrawerOpen] = useState(false);

  const getData = async () => {
    const response = await apiGet('/internal/retail/fulfiller/get_fulfillers');

    if (response.ok) {
      setFulfillers(response.retailFulfillers);
    } else {
      setFulfillers([]);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const handleAddClick = () => {
    setWorkingFulfiller({});
    setDrawerOpen(true);
  };

  const handleEditClick = (fulfiller) => {
    setWorkingFulfiller(fulfiller);
    setDrawerOpen(true);
  };

  const handleClose = () => {
    setWorkingFulfiller({});
    setDrawerOpen(false);
    getData();
  };

  return (
    <RetailPage title="Manage Fulfillers">
      <FulfillerTable
        setDrawerOpen={setDrawerOpen}
        handleEditClick={handleEditClick}
        fulfillers={fulfillers}
      />
      <Button variant="contained" onClick={handleAddClick} sx={{ alignSelf: 'flex-start' }}>
        Add New Fulfiller
      </Button>
      <Drawer
        anchor="right"
        variant="persistent"
        open={drawerOpen}
        classes={{ paper: classes.drawerPaper }}
      >
        {workingFulfiller.id ? (
          <EditFulfillerForm
            classes={{ classes }}
            getData={getData}
            handleClose={handleClose}
            workingFulfiller={workingFulfiller}
          />
        ) : (
          <AddFulfillerForm classes={{ classes }} handleClose={handleClose} getData={getData} />
        )}
      </Drawer>
    </RetailPage>
  );
};

Fulfillers.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default Fulfillers;
