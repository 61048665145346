import * as Sentry from '@sentry/react';

class Logger {
  static info(infoMsg, ...additionalParams) {
    console.log(infoMsg, additionalParams);
  }

  static error(err, ...additionalParams) {
    if (process === undefined || process.env.JEST_WORKER_ID === undefined) {
      // Error log, unless we're running unit tests
      console.error(err, additionalParams);
    }

    // send the data to new relic
    if (process.env.REACT_APP_ENABLE_LOGGING === 'true') {
      Sentry.captureException(err);
    }
  }
}

export default Logger;
