export default {
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '6px',
    border: 'none',
  },
  label: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '24px',
    letterSpacing: '0.15px',
    opacity: '60%',
    margin: '0',
    paddingBottom: '8px',
    marginTop: '-22px',
  },
  headerCount: {
    fontWeight: '700',
  },
  nameCell: {
    paddingTop: '12px',
    paddingBottom: '12px',
    borderBottom: 'none',
    padding: '0px',
    maxWidth: '80px',
  },
  tableRow: {
    backgroundColor: '#FAFAFA',
  },
  mealBadgeName: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '20px',
    letterSpacing: '0.15px',
    paddingLeft: '-0px',
  },
  trashIconCell: {
    padding: '0px',
    borderBottom: 'none',
  },
  drawerPaper: {
    width: '500px',
  },
  titleBar: {
    position: 'relative',
    fontSize: '20px',
    padding: '16px',
  },
  searchIcon: {
    paddingBottom: '2px',
  },
  closeBtn: {
    position: 'absolute',
    top: '0px',
    right: '0px',
  },
  image: {
    maxHeight: '50px',
    maxWidth: '80px',
  },
  imageRow: {
    paddingTop: '6px',
    paddingLeft: '6px',
    borderBottom: 'none',
    maxWidth: '16px',
  },
  hidden: {
    display: 'none',
    visibility: 'hidden',
  },
  visible: {
    display: 'normal',
    visibility: 'visible',
  },
};
