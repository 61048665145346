import { options, handleResponse } from 'lib/homeChefApi';
import { makeQueryParamURL } from 'lib/utils';
import { mapKeysDeep } from 'lib/helpers/objects';
import { snakeCase } from 'lodash';
import { APP_API_URI } from 'lib/constants';

export const CIRF_URL = `${APP_API_URI}/internal/culinary/compound_ingredient_recipe_form`;

export const fetchCompoundIngredientRecipe = async ({ compoundIngredientRecipeId }) => {
  const queryUrl = makeQueryParamURL(`${CIRF_URL}/compound_ingredient_recipe`, {
    compound_ingredient_recipe_id: compoundIngredientRecipeId,
  });

  const response = await fetch(queryUrl, options);

  return handleResponse(response);
};

export const updateCompoundIngredientRecipe = async ({
  compoundIngredientRecipeId,
  compoundIngredientRecipeParams,
}) => {
  const queryUrlParams = compoundIngredientRecipeId
    ? { compound_ingredient_recipe_id: compoundIngredientRecipeId }
    : {};

  const queryUrl = makeQueryParamURL(`${CIRF_URL}/compound_ingredient_recipe`, queryUrlParams);

  const transformedParams = mapKeysDeep(compoundIngredientRecipeParams, (_value, key) => {
    return snakeCase(key);
  });

  const response = await fetch(queryUrl, {
    ...options,
    method: 'POST',
    body: JSON.stringify(transformedParams),
  });

  return handleResponse(response);
};

export const updateCompoundIngredientState = async ({ compoundIngredientId, eventType }) => {
  const queryUrl = `${CIRF_URL}/update_compound_ingredient_state`;

  const transformedParams = {
    compound_ingredient: {
      id: compoundIngredientId,
      event_type: eventType,
    },
  };

  const response = await fetch(queryUrl, {
    ...options,
    method: 'PUT',
    body: JSON.stringify(transformedParams),
  });

  return handleResponse(response);
};
