import { options, handleResponse } from 'lib/homeChefApi';
import { makeQueryParamURL } from 'lib/utils';
import { APP_API_URI } from 'lib/constants';

export const fetchMenu = async ({ menuId }) => {
  const response = await fetch(
    makeQueryParamURL(`${APP_API_URI}/internal/retail/menu_management/get_menu/`, {
      retail_menu_id: menuId,
    }),
    options
  );
  return handleResponse(response);
};

export const createAddMeals = async ({ retailMenuId, retailCategoryId, culinaryVersions }) => {
  const url = `${APP_API_URI}/internal/retail/menu_management/add_meals`;

  const response = await fetch(url, {
    ...options,
    method: 'POST',
    body: JSON.stringify({
      retail_menu_id: retailMenuId,
      retail_category_id: retailCategoryId,
      culinary_versions: culinaryVersions.split('\n'),
    }),
  });

  return handleResponse(response);
};

export const removeMeal = async ({ retailMenuId, categoryId, mealId }) => {
  const url = `${APP_API_URI}/internal/retail/menu_management/remove_meal`;

  const response = await fetch(url, {
    ...options,
    method: 'POST',
    body: JSON.stringify({
      retail_menu_id: retailMenuId,
      retail_category_id: categoryId,
      meal_id: mealId,
    }),
  });

  return handleResponse(response);
};

export const postRankingUpdates = async ({ retailMenuId, retailCategoryId, mealRankings }) => {
  const url = `${APP_API_URI}/internal/retail/menu_management/update_ranking`;
  const response = await fetch(url, {
    ...options,
    method: 'POST',
    body: JSON.stringify({
      retail_menu_id: retailMenuId,
      retail_category_id: retailCategoryId,
      meals: mealRankings,
    }),
  });

  return handleResponse(response);
};
