import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// Material Components
import Card from '@mui/material/Card';

import Dropdown from '../../shared/Dropdown';
import SectionHeader from '../shared/SectionHeader';
import MealType from './MealType';

const ProductType = ({ classes, formState, handleFormState }) => {
  const { formOptions } = useSelector((state) => state.mealEditForm.formOptions);

  const selectProductTypes = useMemo(() => {
    return formOptions.productTypes.map((productType) => ({
      label: productType.name,
      value: productType.id,
      key: productType.id,
    }));
  }, [formOptions.productTypes]);

  const handleProductTypeChange = (event) => {
    const { value } = event.target;
    // Wipe meal type id from store and re-render component
    // does not set dirty, MealType will do that
    handleFormState({ target: { name: 'productTypeId', value } });
    handleFormState({ target: { name: 'mealTypeId', value: null } });
  };

  return (
    <Card className={classes.productTypeCard}>
      <SectionHeader text="Product Type" />

      <Dropdown
        label="Product Type"
        name="productTypeId"
        onChange={handleProductTypeChange}
        options={selectProductTypes}
        value={formState.productTypeId}
        required
      />

      <MealType classes={classes} formState={formState} handleFormState={handleFormState} />
    </Card>
  );
};

ProductType.propTypes = {
  classes: PropTypes.object.isRequired,
  formState: PropTypes.object.isRequired,
  handleFormState: PropTypes.func.isRequired,
};

export default ProductType;
