import { options, handleResponse } from 'lib/homeChefApi';
import { makeQueryParamURL } from 'lib/utils';
import { APP_API_URI } from 'lib/constants';

export const fetchIngredient = async ({ ingredientId }) => {
  const response = await fetch(
    makeQueryParamURL(`${APP_API_URI}/internal/culinary/ingredient_swapper/ingredient`, {
      ingredient_id: ingredientId,
    }),
    options
  );

  return handleResponse(response);
};
