import {
  apiRequestConstant,
  apiSuccessConstant,
  apiErrorConstant,
  APP_API_URI,
} from 'lib/constants';
import Logger from 'lib/Logger';
import axios from 'axios';

const apiRequest = ({ type, params }) => ({
  type: apiRequestConstant(type),
  requestType: type,
  params,
});

const apiSuccess = ({ type, params, response }) => ({
  type: apiSuccessConstant(type),
  requestType: type,
  response,
  params,
});

const apiError = ({ type, params, response, error }) => ({
  type: apiErrorConstant(type),
  requestType: type,
  params,
  response,
  error: {
    ...error,
    type,
    message: response ? response.error : error.message,
  },
});

export const makeApiRequest = ({ type, method, params }) => {
  return async (dispatch, getState) => {
    try {
      dispatch(apiRequest({ type, params }));
      const response = await method(params);
      if (response.ok) {
        dispatch(apiSuccess({ type, params, response }));
      } else {
        Logger.error(`Type: ${type}, Error: ${JSON.stringify(response)}`, {
          type,
          params,
          response,
        });
        dispatch(apiError({ type, params, response }));
      }
      return response;
    } catch (error) {
      Logger.error(error, { type, params, state: getState() });
      dispatch(apiError({ type, params, error }));
      return { ok: false };
    }
  };
};
// using AXIOS

const AXIOS_CONFIG = {
  withCredentials: true,
  headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
};
const errorHandler = (err) => {
  if (err?.response?.status === 401 || err?.response?.status === 403) {
    window.location.replace(APP_API_URI);
    return;
  }
  throw err;
};

export const GET = (url, config = {}) => {
  return axios.get(url, { ...config, ...AXIOS_CONFIG }).catch(errorHandler);
};

export const POST = (url, body, config = {}) => {
  return axios.post(url, body, { ...config, ...AXIOS_CONFIG }).catch(errorHandler);
};

export const DELETE = (url, config = {}) => {
  return axios.delete(url, { ...config, ...AXIOS_CONFIG }).catch(errorHandler);
};

export const PATCH = (url, body, config = {}) => {
  return axios.patch(url, body, { ...config, ...AXIOS_CONFIG }).catch(errorHandler);
};

export const POSTFORM = (url, body, config = {}) => {
  return axios.postForm(url, body, { ...config, ...AXIOS_CONFIG }).catch(errorHandler);
};

export const PUTFORM = (url, body, config = {}) => {
  return axios.putForm(url, body, { ...config, ...AXIOS_CONFIG }).catch(errorHandler);
};
