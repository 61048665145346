import { options, handleResponse } from 'lib/homeChefApi';
import { dashCaseToSnakeCase } from 'lib/utils';
import { APP_API_URI } from 'lib/constants';

const MG_URL = `${APP_API_URI}/internal/culinary/menugrid`;

export const triggerCsvExport = async ({ sheetName, quarterStart, displayGroup }) => {
  const url = `${MG_URL}/${dashCaseToSnakeCase(
    sheetName
  )}_export?quarter=${quarterStart}&display_group=${displayGroup}`;

  const response = await fetch(url, options);
  return handleResponse(response);
};
