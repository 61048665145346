// Dependencies
import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';

// Helpers
import { dollarStringToNumber } from 'lib/helpers/money';

// Material
import TextField from '@mui/material/TextField';

// Custom Components
import NumberFormatter from '../NumberFormatter';

// Example: https://material-ui.com/components/text-fields/#FormattedInputs.js

const MoneyInput = ({
  children,
  classes,
  disabled,
  label,
  name,
  onSetFormState,
  fullWidth,
  defaultValue,
  setFormError,
}) => {
  // State
  const [error, setError] = useState({
    error: false,
  });
  const [helperText, setHelperText] = useState('');
  const [dollarAmount, setDollarAmount] = useState(defaultValue || 0);

  // Handlers
  const handleOnChange = (event) => {
    const convertedInput = dollarStringToNumber(event.target.value);
    setDollarAmount(convertedInput);
  };

  // Helpers
  const validateField = () => {
    let isValid = false;

    if (disabled) {
      setError({
        error: false,
      });
      setHelperText('');
      isValid = true;
      setFormError(false);
      return isValid;
    }

    if (dollarAmount <= 0) {
      setError({
        error: true,
      });
      setHelperText('Discounts must be more than $0');
      setFormError(true);
    } else if (dollarAmount > 20) {
      setError({
        error: true,
      });
      setHelperText('Discounts must be $20 or less');
      setFormError(true);
    } else {
      setError({
        error: false,
      });
      setHelperText('');
      isValid = true;
      setFormError(false);
    }

    return isValid;
  };

  const validateAndCallAPI = async () => {
    const isValid = await validateField();

    if (isValid) {
      onSetFormState(dollarAmount);
    }
  };

  // eslint-disable-next-line
  const debouncedValidateAndCallAPI = useCallback(debounce(validateAndCallAPI, 250), [
    dollarAmount,
  ]);

  // Hooks
  useEffect(() => {
    debouncedValidateAndCallAPI();

    // Cancel the debounce on useEffect cleanup.
    return debouncedValidateAndCallAPI.cancel;
  }, [dollarAmount, debouncedValidateAndCallAPI]);

  useEffect(() => {
    setDollarAmount(defaultValue);
  }, [defaultValue, setDollarAmount]);

  return (
    <TextField
      className={classes.textInput}
      disabled={disabled}
      focused={dollarAmount >= 0}
      error={error.error}
      helperText={helperText}
      label={label}
      name={name}
      onChange={handleOnChange}
      value={dollarAmount}
      variant="outlined"
      InputProps={{
        inputComponent: NumberFormatter,
      }}
      fullWidth={fullWidth}
    >
      {children}
    </TextField>
  );
};

MoneyInput.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  classes: PropTypes.object.isRequired,
  defaultValue: PropTypes.number,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  onSetFormState: PropTypes.func,
  setFormError: PropTypes.func.isRequired,
};

MoneyInput.defaultProps = {
  children: [],
  defaultValue: 0,
  disabled: false,
  fullWidth: false,
  label: '',
  name: '',
  onSetFormState: () => {},
};

export default MoneyInput;
