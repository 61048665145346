import { APP_API_URI } from 'lib/constants';

export const makeQueryParamURL = (originalURL, params = {}) => {
  const url = new URL(originalURL);

  Object.keys(params).forEach((key) => {
    if (Array.isArray(params[key])) {
      const queryKey = `${key}[]`;
      params[key].forEach((value) => {
        url.searchParams.append(queryKey, value);
      });
    } else {
      url.searchParams.append(key, params[key]);
    }
  });
  return url;
};

const sendMessageToParent = (data) => window.parent.postMessage(data, `${APP_API_URI}/`);

export const broadcastScrollSizeToParent = () => {
  if (window === window.parent) {
    return;
  }

  const { body } = document;
  const html = document.documentElement;
  const realHeight = Math.max(body.scrollHeight, body.offsetHeight, html.offsetHeight) + 5;

  sendMessageToParent({
    messageType: 'scrollSize',
    window: {
      scrollHeight: realHeight,
      scrollWidth: document.body.scrollWidth,
    },
  });
};

export const currentHeight = () => {
  return window.outerHeight;
};

export const broadcastScrollTopToParent = () => {
  if (window === window.parent) {
    return;
  }
  sendMessageToParent({
    messageType: 'scrollTop',
  });
  window.scrollTo(0, 0); // brute force jump to top
};

// flatten error messages and remove null/blanks
export const cleanArray = (arr) => {
  return arr.flat().filter((item) => {
    return !!item;
  });
};

export const camelCaseToSnakeCase = (value) => value.replace(/([A-Z])/g, '_$1').toLowerCase();

export const dashCaseToSnakeCase = (value) => value.replace(/-/g, '_');

export const sortByDisplayOrder = (a, b) => (a.displayOrder > b.displayOrder ? 1 : -1);

export const humanizeDashCase = (value) =>
  value
    .replace(/-/g, ' ')
    .split(' ')
    .map((string) => string.charAt(0).toUpperCase() + string.slice(1))
    .join(' ');
