import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { apiGet } from 'lib/homeChefApi';
import useJobDetail from 'lib/useJobDetail';

// Material
import { Alert, Button, Drawer, Snackbar } from '@mui/material';

import RetailPage from 'components/Retail/Page';

// Components
import CategoryTable from './CategoryTable';
import AddCategoryForm from './ManageCategoryForm/AddCategoryForm';
import EditCategoryForm from './ManageCategoryForm/EditCategoryForm';

const Categories = ({ classes }) => {
  const [categories, setCategories] = useState([]);
  const [workingCategory, setWorkingCategory] = useState({});
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [snackState, setSnackState] = useState({});
  const [isUpdating, setIsUpdating] = useState(false);
  const [jobId, setJobId] = useState(null);

  const getData = async () => {
    const response = await apiGet('/internal/retail/category/get_categories');

    if (response.ok) {
      setCategories(response.retailCategories);
    } else {
      setCategories([]);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const handleAddClick = () => {
    setWorkingCategory({});
    setDrawerOpen(true);
  };

  const handleEditClick = (category) => {
    setWorkingCategory(category);
    setDrawerOpen(true);
  };

  const handleClose = () => {
    setWorkingCategory({});
    setDrawerOpen(false);
  };

  const handleSnackClose = () => {
    setSnackState({});
  };

  const archiveMessage = (detail) => {
    if ('deletedAssortmentCount' in detail) {
      return `Successfully archived ${detail.retailCategory.name} and deleted ${detail.deletedAssortmentCount} assortments`;
    }
    return `Successfully activated ${detail.retailCategory.name} and created ${detail.createdAssortmentCount} assortments with ${detail.failureCount} failures`;
  };

  const handleJobResult = (detail) => {
    setIsUpdating(false);
    setJobId(null);
    setSnackState({
      message: archiveMessage(detail),
      severity: 'success',
    });
    getData();
  };

  const handleJobRetrying = (detail) => {
    setSnackState({ message: detail.error, severity: 'error' });
  };

  const handleJobDead = (detail) => {
    setIsUpdating(false);
    setJobId(null);
    setSnackState({
      message: detail.error,
      severity: 'error',
    });
  };

  // eslint-disable-next-line no-unused-vars
  const jobDetail = useJobDetail({
    jobClass: 'Retail::UpdateAssortmentCategoriesWorker',
    jid: jobId,
    onJobResult: handleJobResult,
    onJobRetrying: handleJobRetrying,
    onJobDead: handleJobDead,
    refreshRate: 5000,
  });

  return (
    <RetailPage title="Manage Product Lines">
      <CategoryTable
        setDrawerOpen={setDrawerOpen}
        handleEditClick={handleEditClick}
        categories={categories}
      />
      <Button variant="contained" onClick={handleAddClick} sx={{ alignSelf: 'flex-start' }}>
        Add Product Line
      </Button>
      <Drawer
        anchor="right"
        variant="persistent"
        open={drawerOpen}
        classes={{ paper: classes.drawerPaper }}
      >
        {workingCategory.id ? (
          <EditCategoryForm
            classes={{ classes }}
            getData={getData}
            handleClose={handleClose}
            workingCategory={workingCategory}
            setSnackState={setSnackState}
            setJobId={setJobId}
            isUpdating={isUpdating}
            setIsUpdating={setIsUpdating}
          />
        ) : (
          <AddCategoryForm
            classes={{ classes }}
            handleClose={handleClose}
            getData={getData}
            setJobId={setJobId}
            setSnackState={setSnackState}
          />
        )}
      </Drawer>
      <Snackbar
        open={'severity' in snackState}
        autoHideDuration={6000}
        onClose={handleSnackClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert severity={snackState.severity}>{snackState.message}</Alert>
      </Snackbar>
    </RetailPage>
  );
};

Categories.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default Categories;
