import {
  GRID_DATA_REQUEST,
  GRID_DATA_SUCCESS,
  GRID_DATA_ERROR,
  UPDATE_SELECTION_ORDER_SUCCESS,
  UPDATE_SELECTION_ORDER_ERROR,
  UPDATE_SELECTION_ORDER_ON_GRID,
  ADD_STAGING_SELECTIONS_SUCCESS,
  ADD_STAGING_SELECTIONS_ERROR,
  TOGGLE_ADD_MEALS_DIALOG,
  TOGGLE_SNACK_BAR,
  ADD_PLACEHOLDER,
  REMOVE_PLACEHOLDER,
  REMOVE_MEAL,
} from 'lib/constants';
import {
  addPlaceholderToMenu,
  displayPlaceholdersOnMenu,
  reorderPlaceholders,
  reorderPlaceholdersOnChangedMenus,
} from 'lib/helpers/menugrid/beautifulMenugridPlaceholderManager';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';

export const initialState = {
  menus: [],
  menusWithPlaceholders: [],
  stagingGroup: {},
  stagingGroupWithPlaceholders: {},
  error: false,
  fetching: true,
  dragging: false,
  updateMenuSelectionOrderError: false,
  openAddMealsDialog: false,
  addMealsErrorMessage: '',
  openSnackBar: false,
  snackBarMessage: '',
};

export default (state = initialState, action = { type: null }) => {
  switch (action.type) {
    case GRID_DATA_REQUEST: {
      return {
        ...state,
        error: false,
        fetching: true,
        updateMenuSelectionOrderError: false,
      };
    }
    case GRID_DATA_SUCCESS: {
      const menus = cloneDeep(action.response.menus);
      const stagingGroup = cloneDeep(action.response.stagingGroup);

      const menusWithPlaceholders = menus.map((menu) =>
        displayPlaceholdersOnMenu(menu, 'standard')
      );
      const stagingGroupWithPlaceholders = displayPlaceholdersOnMenu(stagingGroup, 'staging');

      return {
        ...state,
        menus: action.response.menus,
        menusWithPlaceholders,
        stagingGroup: action.response.stagingGroup,
        stagingGroupWithPlaceholders,
        error: false,
        fetching: false,
      };
    }
    case GRID_DATA_ERROR: {
      return {
        ...state,
        error: true,
        fetching: false,
      };
    }
    case UPDATE_SELECTION_ORDER_ON_GRID: {
      const stagingGroup = action.payload.menus.find((menu) => menu.staging);
      const menus = action.payload.menus.filter((menu) => !menu.staging);

      const menusToUpdate = state.menusWithPlaceholders;

      const updatedMenus = menusToUpdate.map((menu) => {
        return menus.find((match) => match.id === menu.id) || menu;
      });

      const updatedStagingGroup = stagingGroup || state.stagingGroupWithPlaceholders;

      const newMenusState = { menusWithPlaceholders: updatedMenus };

      return {
        ...state,
        ...newMenusState,
        stagingGroupWithPlaceholders: updatedStagingGroup,
        dragging: true,
      };
    }
    case UPDATE_SELECTION_ORDER_ERROR: {
      return {
        ...state,
        updateMenuSelectionOrderError: true,
        dragging: false,
      };
    }
    case UPDATE_SELECTION_ORDER_SUCCESS: {
      const { menuType } = action.params;
      const { menuSelections, standardId } = state.stagingGroupWithPlaceholders;
      const updatedStaging = isEmpty(action.params.stagingGroup)
        ? menuSelections
        : reorderPlaceholders(menuSelections, standardId, 'staging');

      const updatedMenuIds = action.params.menus.map((menu) => menu.id);
      const menuStateToUpdate = state.menusWithPlaceholders;
      const updatedMenus =
        updatedMenuIds.length === 0
          ? menuStateToUpdate
          : reorderPlaceholdersOnChangedMenus(menuStateToUpdate, updatedMenuIds, menuType);

      const newMenuState = { menusWithPlaceholders: updatedMenus };

      return {
        ...state,
        ...newMenuState,
        stagingGroupWithPlaceholders: {
          ...state.stagingGroupWithPlaceholders,
          menuSelections: updatedStaging,
        },
        dragging: false,
      };
    }
    case TOGGLE_ADD_MEALS_DIALOG: {
      return {
        ...state,
        openAddMealsDialog: action.payload.toggle,
        addMealsErrorMessage: '',
      };
    }
    case ADD_STAGING_SELECTIONS_SUCCESS: {
      const prevStagingGroupMenuSelections = state.stagingGroup.menuSelections || [];

      const updatedStagingGroup = {
        ...state.stagingGroup,
        menuSelections: [
          ...prevStagingGroupMenuSelections,
          ...action.response.stagingGroupSelections,
        ],
      };

      const updatedStagingGroupWithPlaceholders = displayPlaceholdersOnMenu(
        updatedStagingGroup,
        'staging'
      );

      return {
        ...state,
        openAddMealsDialog: false,
        addMealsErrorMessage: '',
        stagingGroup: updatedStagingGroup,
        stagingGroupWithPlaceholders: updatedStagingGroupWithPlaceholders,
      };
    }
    case ADD_STAGING_SELECTIONS_ERROR: {
      return {
        ...state,
        addMealsErrorMessage: `Error: ${action.response.errors}`,
      };
    }
    case TOGGLE_SNACK_BAR: {
      return {
        ...state,
        openSnackBar: action.payload.toggle,
        snackBarMessage: action.payload.message,
      };
    }
    case ADD_PLACEHOLDER: {
      const menusAffected = state.menusWithPlaceholders;

      const menusWithNewPlaceholder = menusAffected.map((menu) => {
        return menu.id === action.payload.menuId
          ? addPlaceholderToMenu(menu, action.payload.menuType)
          : menu;
      });

      const newState = { menusWithPlaceholders: menusWithNewPlaceholder };

      return {
        ...state,
        ...newState,
      };
    }
    case REMOVE_PLACEHOLDER: {
      const menusAffected = state.menusWithPlaceholders;

      const menus = menusAffected.map((menu) => {
        const updatedMenu = menu;
        updatedMenu.menuSelections = menu.menuSelections.filter(
          (el) => el.id !== action.payload.id
        );
        return updatedMenu;
      });

      const menusState = { menusWithPlaceholders: menus };

      const stagingGroupMenuSelections = state.stagingGroupWithPlaceholders.menuSelections.filter(
        (el) => el.id !== action.payload.id
      );

      return {
        ...state,
        ...menusState,
        stagingGroupWithPlaceholders: {
          ...state.stagingGroupWithPlaceholders,
          menuSelections: stagingGroupMenuSelections,
        },
      };
    }
    case REMOVE_MEAL: {
      return {
        ...state,
        stagingGroup: {
          ...state.stagingGroup,
          menuSelections: [
            ...state.stagingGroup.menuSelections.filter(
              (el) => el.id !== action.payload.menuSelectionId
            ),
          ],
        },
        stagingGroupWithPlaceholders: {
          ...state.stagingGroupWithPlaceholders,
          menuSelections: [
            ...state.stagingGroupWithPlaceholders.menuSelections.filter(
              (el) => el.id !== action.payload.menuSelectionId
            ),
          ],
        },
      };
    }
    default:
      return state;
  }
};
