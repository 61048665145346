import {
  ISW_SWAPPABLE_INGREDIENT,
  ISW_SWAP_INGREDIENT,
  ISW_RESET_ERROR_MESSAGE,
} from 'lib/constants';
import { makeApiRequest } from 'lib/api';
import { fetchIngredient, swapIngredient } from './services';

export const getSwappableIngredient = (ingredientId) => {
  return async (dispatch) => {
    await dispatch(
      makeApiRequest({
        type: ISW_SWAPPABLE_INGREDIENT,
        method: fetchIngredient,
        params: { ingredientId },
      })
    );
  };
};

export const postSwapIngredient = (swappableIngredientAssignment, targetIngredient) => {
  return async (dispatch) => {
    await dispatch(
      makeApiRequest({
        type: ISW_SWAP_INGREDIENT,
        method: swapIngredient,
        params: { swappableIngredientAssignment, targetIngredient },
      })
    );
  };
};

export const resetErrorMessage = () => ({
  type: ISW_RESET_ERROR_MESSAGE,
});
