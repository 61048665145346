import { apiGet } from 'lib/homeChefApi';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Alert from '@mui/material/Alert';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Skeleton } from '@mui/material';

const PackingFacilitySelector = ({ classes, value, onChange }) => {
  const [packingFacilities, setPackingFacilities] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    const fetchPackingFacilities = async () => {
      setFetching(true);
      const response = await apiGet(
        'internal/retail/flexible_volume_changes/get_packing_facilities'
      );

      setFetching(false);

      if (response.ok) {
        setPackingFacilities(response.packingFacilities);
      } else {
        setErrorMessage(response.error);
      }
    };

    fetchPackingFacilities();
  }, []);

  if (errorMessage) {
    return <Alert severity="error">{errorMessage}</Alert>;
  }

  if (packingFacilities === null || fetching) {
    return <Skeleton variant="rectangular" width={200} height={58} />;
  }

  return (
    <ToggleButtonGroup label="Packing Facility" exclusive size="large">
      {packingFacilities &&
        packingFacilities.map((pf) => {
          return (
            <ToggleButton
              onClick={() => onChange(pf)}
              selected={value && pf.id === value.id}
              key={pf.id}
              value={pf.id}
              className={classes.packingFacilityButton}
            >
              {pf.name}
            </ToggleButton>
          );
        })}
    </ToggleButtonGroup>
  );
};

PackingFacilitySelector.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.object,
  onChange: PropTypes.func,
};

PackingFacilitySelector.defaultProps = {
  value: null,
  onChange: () => {},
};

export default PackingFacilitySelector;
