import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import {
  Alert,
  Button,
  MenuItem,
  RadioGroup,
  TextField,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  Divider,
  Container,
  ToggleButtonGroup,
  ToggleButton,
  Grid,
} from '@mui/material';
import isEmpty from 'lodash/isEmpty';

import { Loading } from 'components/shared';
import { defaultDisclaimerText } from 'lib/helpers/behavioralDiscountTool/defaultDisclaimerText';
import Logger from 'lib/Logger';
import { cleanArray } from 'lib/utils';

import {
  fetchBehavioralDiscount,
  createBehavioralDiscount,
  updateBehavioralDiscount,
  fetchFutureMenus,
  fetchMenuMeals,
  fetchMealBadges,
  fetchProductTypes,
} from 'services/behavioralDiscountTool';

import { centsToDollars, dollarsToCents } from 'lib/helpers/money';
import {
  bannerTemplates,
  camelToSnakeCase,
  getColorTheme,
} from 'lib/helpers/behavioralDiscountTool/bannerHelper';
import MoneyInput from './MoneyInput';
import PercentFormatter from './PercentFormatter/PercentFormatter';
import MealSelector from './MealSelector';
import ProductTypeSelector from './ProductTypeSelector';
import ConfirmationDialog from './ConfirmationDialog';
import BannerSelector from './BannerSelector';
import BannerPreview from './BannerPreview';
import MealBadgeSelector from './MealBadgeSelector';

const BehavioralDiscountTool = ({ classes }) => {
  const [bannerTheme, setBannerTheme] = useState('default');
  const [dashboardTagText, setDashboardTagText] = useState('OFFER AVAILABLE');
  const [discountType, setDiscountType] = useState('cents');
  const [discountName, setDiscountName] = useState('');
  const [discountPromoText, setDiscountPromoText] = useState('');
  const [discountDescriptionText, setDiscountDescriptionText] = useState('');
  const [disclaimerText, setDisclaimerText] = useState(defaultDisclaimerText);
  const [disableAmountInput, setDisableAmountInput] = useState(true);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [discountPercent, setDiscountPercent] = useState(0);
  const [discountActiveMenuId, setDiscountActiveMenuId] = useState('');
  const [discountEligibleOrderableUnits, setDiscountEligibleOrderableUnits] = useState('');
  const [discountTriggerType, setDiscountTriggerType] = useState('basket');
  const [discountFrequency, setDiscountFrequency] = useState('menu');
  const [discountTriggerMeals, setDiscountTriggerMeals] = useState([]);
  const [durationInWeeks, setDurationInWeeks] = useState(1);
  const [fileName, setFilename] = useState(null);
  const [formError, setFormError] = useState('');
  const [isDiscountUnsaved, setIsDiscountUnsaved] = useState(false);
  const [discountRewardType, setDiscountRewardType] = useState('basket');
  const [discountRewardMeals, setDiscountRewardMeals] = useState([]);
  const [maxOrderableUnits, setMaxOrderableUnits] = useState(0);
  const [discountRewardProductTypeIds, setDiscountRewardProductTypeIds] = useState([]);
  const [mealBadgeId, setMealBadgeId] = useState(null);
  const [filteredMeals, setFilteredMeals] = useState([]);
  const [serviceType, setServiceType] = useState('core');
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [newServiceType, setNewServiceType] = useState('');
  const [newDiscountFrequency, setNewDiscountFrequency] = useState('');
  const [photoFormState, setPhotoFormState] = useState(null);
  const [currentPhoto, setCurrentPhoto] = useState(null);
  const [temporaryImage, setTemporaryImage] = useState(null);
  const [mealBadges, setMealBadges] = useState([]);
  const [apiErrorMessage, setApiErrorMessage] = useState('');
  const [behavioralDiscount, setBehavioralDiscount] = useState({});
  const [fetchingBehavioralDiscount, setFetchingBehavioralDiscount] = useState(false);
  const [userCount, setUserCount] = useState(0);
  const [productTypes, setProductTypes] = useState([]);
  const [menus, setMenus] = useState([]);
  const [meals, setMeals] = useState([]);
  const [posting, setPosting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [successMessages, setSuccessMessages] = useState([]);

  const { behavioralDiscountId } = useParams();

  useEffect(() => {
    if (behavioralDiscountId && isEmpty(behavioralDiscount)) {
      setFetchingBehavioralDiscount(true);
      fetchBehavioralDiscount(behavioralDiscountId)
        .then((response) => {
          setBehavioralDiscount(response.data.behavioralDiscount);
          setUserCount(response.data.userCount);
          setFetchingBehavioralDiscount(false);
        })
        .catch((e) => {
          setApiErrorMessage(e.response.data.error);
          setFetchingBehavioralDiscount(false);
          Logger.error(e.response.data.error);
        });
    } else if (typeof behavioralDiscountId === 'undefined') {
      setDisableAmountInput(false);
    }
  }, [behavioralDiscountId, behavioralDiscount]);

  useEffect(() => {
    if (!isEmpty(behavioralDiscount)) {
      setServiceType(behavioralDiscount.serviceType);
      setBannerTheme(getColorTheme(behavioralDiscount.bannerBackgroundColor));
      setDashboardTagText(behavioralDiscount.dashboardTagText);
      setDiscountType(behavioralDiscount.discountType);
      setDiscountName(behavioralDiscount.name);
      setDiscountPromoText(behavioralDiscount.promoText);
      setDiscountDescriptionText(behavioralDiscount.description);
      setDisclaimerText(behavioralDiscount.disclaimer);
      setDisableAmountInput(behavioralDiscount.discountType === 'percent');
      setDiscountAmount(centsToDollars(behavioralDiscount.valueCents));
      setDiscountPercent(behavioralDiscount.valuePercent);
      setDiscountActiveMenuId(behavioralDiscount.activeMenu);
      setDiscountEligibleOrderableUnits(behavioralDiscount.eligibleOrderableUnits);
      setDiscountTriggerType(behavioralDiscount.triggerType);
      setDiscountFrequency(behavioralDiscount.discountDuration);
      setDiscountTriggerMeals(behavioralDiscount.triggerMeals);
      setDurationInWeeks(
        behavioralDiscount.durationInWeeks === null
          ? 'For life/event-based'
          : behavioralDiscount.durationInWeeks
      );
      setMaxOrderableUnits(behavioralDiscount.maxOrderableUnits);
      setDiscountRewardType(behavioralDiscount.discountTarget);
      setDiscountRewardMeals(behavioralDiscount.rewardMeals);
      setDiscountRewardProductTypeIds(behavioralDiscount.rewardProductTypeIds);
      setMealBadgeId(behavioralDiscount.mealBadgeId);
      setCurrentPhoto(behavioralDiscount.photo);
      setTemporaryImage(behavioralDiscount.photo.url);
      setFilename(behavioralDiscount.photo.filename);
    }
  }, [behavioralDiscount]);

  useEffect(() => {
    if (!menus.length) {
      fetchFutureMenus()
        .then((response) => {
          setMenus(response.data.menus);
        })
        .catch((e) => {
          setApiErrorMessage(e.response.data.error);
          Logger.error(e.response.data.error);
        });
    }
  }, [menus]);

  useEffect(() => {
    if (discountActiveMenuId !== '' && discountActiveMenuId !== null) {
      fetchMenuMeals(discountActiveMenuId)
        .then((response) => {
          setMeals(response.data.meals);
        })
        .catch((e) => {
          setApiErrorMessage(e.response.data.error);
          Logger.error(e.response.data.error);
        });
    }
  }, [discountActiveMenuId]);

  useEffect(() => {
    if (discountFrequency === 'recurring' && !productTypes.length) {
      fetchProductTypes()
        .then((response) => {
          setProductTypes(response.data.productTypes);
        })
        .catch((e) => {
          setApiErrorMessage(e.response.data.error);
          Logger.error(e.response.data.error);
        });
    }
  }, [discountFrequency, productTypes]);

  useEffect(() => {
    const filterMealsByServiceType = async () => {
      const mealsByServiceType = await meals.filter((meal) => meal.serviceType === serviceType);
      setFilteredMeals(mealsByServiceType);
    };

    filterMealsByServiceType();
  }, [meals, serviceType]);

  useEffect(() => {
    if (currentPhoto) {
      setCurrentPhoto(currentPhoto);
    }
  }, [currentPhoto]);

  useEffect(() => {
    if (discountRewardType === 'meal' || discountRewardType === 'product_type') {
      fetchMealBadges()
        .then((response) => {
          setMealBadges(response.data.mealBadges);
        })
        .catch((e) => {
          setApiErrorMessage(e.response.data.error);
          Logger.error(e.response.data.error);
        });
    } else {
      setMealBadgeId(null);
    }
  }, [discountRewardType]);

  if (fetchingBehavioralDiscount || posting) {
    return <Loading />;
  }

  const clearForm = () => {
    setBannerTheme('default');
    setDashboardTagText('');
    setDiscountType('cents');
    setDiscountName('');
    setDiscountPromoText('');
    setDiscountDescriptionText('');
    setDisclaimerText(defaultDisclaimerText);
    setDiscountAmount(0);
    setDiscountPercent(0);
    setDiscountActiveMenuId('');
    setDiscountEligibleOrderableUnits('');
    setDiscountTriggerType('basket');
    setDiscountFrequency('menu');
    setDiscountTriggerMeals([]);
    setDiscountRewardType('basket');
    setDiscountRewardMeals([]);
    setDiscountRewardProductTypeIds([]);
    setDurationInWeeks(1);
    setMealBadgeId(null);
    setFilename(null);
    setMaxOrderableUnits(0);
    setNewServiceType('');
    setPhotoFormState({});
    setShowConfirmationDialog(false);
    setServiceType('core');
    setTemporaryImage(null);
  };

  const revertForm = async () => {
    await setServiceType(behavioralDiscount.serviceType);
    setBannerTheme(getColorTheme(behavioralDiscount.bannerBackgroundColor));
    setDashboardTagText(behavioralDiscount.dashboardTagText);
    setDiscountType(behavioralDiscount.discountType);
    setDiscountName(behavioralDiscount.name);
    setDiscountPromoText(behavioralDiscount.promoText);
    setDiscountDescriptionText(behavioralDiscount.description);
    setDisclaimerText(behavioralDiscount.disclaimer);
    setDiscountAmount(centsToDollars(behavioralDiscount.valueCents));
    setDiscountPercent(behavioralDiscount.valuePercent);
    setDiscountActiveMenuId(behavioralDiscount.activeMenu);
    setDiscountEligibleOrderableUnits(behavioralDiscount.eligibleOrderableUnits);
    setDiscountTriggerType(behavioralDiscount.triggerType);
    setDiscountFrequency(behavioralDiscount.discountDuration);
    setDiscountTriggerMeals(behavioralDiscount.triggerMeals);
    setDiscountRewardMeals(behavioralDiscount.rewardMeals);
    setDiscountRewardType(behavioralDiscount.discountTarget);
    setDiscountRewardProductTypeIds(behavioralDiscount.rewardProductTypeIds);
    setDurationInWeeks(behavioralDiscount.durationInWeeks);
    setMealBadgeId(behavioralDiscount.mealBadgeId);
    setFilename(null);
    setMaxOrderableUnits(behavioralDiscount.maxOrderableUnits);
    setPhotoFormState({});
    setTemporaryImage(null);
  };

  const resetDiscountFields = () => {
    setDiscountTriggerType('basket');
    setDiscountRewardType('basket');
    setDiscountTriggerMeals([]);
    setDiscountRewardMeals([]);
    setDiscountRewardProductTypeIds([]);
    setMealBadgeId(null);
  };

  const onServiceTypeChange = () => {
    resetDiscountFields();
    setShowConfirmationDialog(false);
    setServiceType(newServiceType);
    setNewServiceType('');
  };

  const onDiscountFrequencyChange = () => {
    resetDiscountFields();
    setShowConfirmationDialog(false);
    setDiscountFrequency(newDiscountFrequency);
    setNewDiscountFrequency('');
  };

  const onCancelConfirmationDialogChange = () => {
    setShowConfirmationDialog(false);
  };

  const onMenuChange = (newMenu) => {
    setDiscountTriggerMeals([]);
    setDiscountRewardMeals([]);
    setDiscountRewardProductTypeIds([]);
    setDiscountActiveMenuId(newMenu);

    fetchMenuMeals(newMenu)
      .then((response) => {
        setMeals(response.data.meals);
      })
      .catch((e) => {
        setApiErrorMessage(e.response.data.error);
        Logger.error(e.response.data.error);
      });
  };

  const onDurationChange = (newDurationInWeeks) => {
    setDurationInWeeks(newDurationInWeeks);
  };

  const renderSuccessMessages = () => {
    return successMessages.map((message) => {
      return (
        <Alert key={message} severity="success" variant="filled">
          {message}
        </Alert>
      );
    });
  };

  const renderErrorMessage = () => {
    return (
      <Alert severity="error" variant="filled">
        {apiErrorMessage}
      </Alert>
    );
  };

  const renderClearFormMessage = () => {
    const key = behavioralDiscountId || new Date().getTime();
    return (
      <Alert key={`${key}-not-saved`} severity="warning" variant="filled">
        Discount not saved
      </Alert>
    );
  };

  const renderConfirmationDialog = () => {
    let text;

    if (newServiceType) {
      text = `Products have been selected for <${serviceType.toUpperCase()}>.
        You will need to select new products if you change the service type.`;

      return (
        <ConfirmationDialog
          open={showConfirmationDialog}
          onConfirm={onServiceTypeChange}
          onCancel={onCancelConfirmationDialogChange}
          text={text}
        />
      );
    }

    if (newDiscountFrequency) {
      text = `Products have been selected for '${discountFrequency}' discount frequency.
        You will need to select new products if you change the discount frequency.`;

      return (
        <ConfirmationDialog
          open={showConfirmationDialog}
          onConfirm={onDiscountFrequencyChange}
          onCancel={onCancelConfirmationDialogChange}
          text={text}
        />
      );
    }

    return null;
  };

  const handleServiceTypeChange = (newType) => {
    if (serviceType === newType) return;

    if (
      discountTriggerMeals.length === 0 &&
      discountRewardMeals.length === 0 &&
      discountRewardProductTypeIds.length === 0
    ) {
      setServiceType(newType);
    } else {
      setNewServiceType(newType);
      setShowConfirmationDialog(true);
    }
  };

  const handleDiscountFrequencyChange = (newFrequency) => {
    if (discountFrequency === newFrequency) return;

    if (
      discountTriggerMeals.length === 0 &&
      discountRewardMeals.length === 0 &&
      discountRewardProductTypeIds.length === 0
    ) {
      setDiscountFrequency(newFrequency);
    } else {
      setNewDiscountFrequency(newFrequency);
      setShowConfirmationDialog(true);
    }
  };

  const handleDiscountTypeChange = (newType) => {
    setDiscountType(newType);
    if (newType === 'cents') {
      setDiscountPercent(0);
      setDisableAmountInput(false);
    } else if (newType === 'percent') {
      setDiscountAmount(0);
      setDisableAmountInput(true);
    }
  };

  const handleRewardTypeChange = (rewardType) => {
    setDiscountRewardType(rewardType);
    if (rewardType === 'shipping') {
      setDiscountType('percent');
      setDiscountPercent(100);
    } else if (rewardType === 'pickable_item') {
      setDiscountAmount(0);
      setFormError(false);
    } else {
      setDiscountAmount(0);
      setDiscountPercent(0);
    }
  };

  const handleFormSave = () => {
    setApiErrorMessage('');
    setPosting(true);
    const behavioralDiscountData = new FormData();
    if (behavioralDiscountId) {
      behavioralDiscountData.append('id', behavioralDiscountId);
      behavioralDiscountData.append('dashboard_tag_text', dashboardTagText);
      behavioralDiscountData.append('description', discountDescriptionText);
      behavioralDiscountData.append('disclaimer', disclaimerText);
      behavioralDiscountData.append('discount_duration', discountFrequency);
      behavioralDiscountData.append('discount_target', discountRewardType);
      behavioralDiscountData.append('discount_type', discountType);
      behavioralDiscountData.append('duration_in_weeks', durationInWeeks);
      behavioralDiscountData.append('eligible_orderable_units', discountEligibleOrderableUnits);
      behavioralDiscountData.append('max_orderable_units', maxOrderableUnits);
      behavioralDiscountData.append('menu_id', discountActiveMenuId);
      behavioralDiscountData.append('name', discountName);
      behavioralDiscountData.append('promotion_text', discountPromoText);
      behavioralDiscountData.append('reward_meals', discountRewardMeals);
      behavioralDiscountData.append('reward_product_type_ids', discountRewardProductTypeIds);
      behavioralDiscountData.append('meal_badge_id', mealBadgeId);
      behavioralDiscountData.append('service_type', serviceType);
      behavioralDiscountData.append('trigger_meals', discountTriggerMeals);
      behavioralDiscountData.append('trigger_type', discountTriggerType);
      behavioralDiscountData.append('value_cents', dollarsToCents(discountAmount));
      behavioralDiscountData.append('value_percent', parseInt(discountPercent, 10));

      if (currentPhoto?.deleted) {
        behavioralDiscountData.append('photo_deleted', currentPhoto.deleted);
      } else if (photoFormState?.name) {
        behavioralDiscountData.append('photo_file', photoFormState);
      }

      Object.keys(bannerTemplates[bannerTheme]).forEach((key) => {
        behavioralDiscountData.append(
          `${camelToSnakeCase(key)}`,
          bannerTemplates[bannerTheme][key]
        );
      });

      updateBehavioralDiscount(behavioralDiscountData)
        .then((response) => {
          setBehavioralDiscount(response.data.behavioralDiscount);
          setPosting(false);
          setSuccess(true);
          setSuccessMessages(cleanArray(['Behavioral Discount Updated!']));
        })
        .catch((e) => {
          setApiErrorMessage(e.response.data.error);
          setPosting(false);
          setSuccess(false);
        });
    } else {
      behavioralDiscountData.append('dashboard_tag_text', dashboardTagText);
      behavioralDiscountData.append('description', discountDescriptionText);
      behavioralDiscountData.append('disclaimer', disclaimerText);
      behavioralDiscountData.append('discount_duration', discountFrequency);
      behavioralDiscountData.append('discount_target', discountRewardType);
      behavioralDiscountData.append('discount_type', discountType);
      behavioralDiscountData.append('eligible_orderable_units', discountEligibleOrderableUnits);
      behavioralDiscountData.append('duration_in_weeks', durationInWeeks);
      behavioralDiscountData.append('max_orderable_units', maxOrderableUnits);
      behavioralDiscountData.append('menu_id', discountActiveMenuId);
      behavioralDiscountData.append('name', discountName);
      behavioralDiscountData.append('promotion_text', discountPromoText);
      behavioralDiscountData.append('reward_meals', discountRewardMeals);
      behavioralDiscountData.append('reward_product_type_ids', discountRewardProductTypeIds);
      behavioralDiscountData.append('service_type', serviceType);
      behavioralDiscountData.append('trigger_meals', discountTriggerMeals);
      behavioralDiscountData.append('trigger_type', discountTriggerType);
      behavioralDiscountData.append('meal_badge_id', mealBadgeId);
      behavioralDiscountData.append('value_cents', dollarsToCents(discountAmount));
      behavioralDiscountData.append('value_percent', parseInt(discountPercent, 10));

      if (photoFormState?.name) {
        behavioralDiscountData.append('photo_file', photoFormState);
      }

      Object.keys(bannerTemplates[bannerTheme]).forEach((key) => {
        behavioralDiscountData.append(
          `${camelToSnakeCase(key)}`,
          bannerTemplates[bannerTheme][key]
        );
      });

      createBehavioralDiscount(behavioralDiscountData)
        .then((response) => {
          setBehavioralDiscount(response.data.behavioralDiscount);
          setPosting(false);
          setSuccess(true);
          setSuccessMessages(cleanArray(['Behavioral Discount Created!']));
        })
        .catch((e) => {
          setApiErrorMessage(e.response.data.error);
          setPosting(false);
          setSuccess(false);
        });
    }
    setIsDiscountUnsaved(false);
  };

  const formattedMenu = (endsAt) => {
    return new Date(endsAt).toDateString();
  };

  return (
    <div>
      {renderConfirmationDialog()}
      {success && renderSuccessMessages()}
      {apiErrorMessage.length > 0 && renderErrorMessage()}
      {isDiscountUnsaved && renderClearFormMessage()}
      <div className={classes.root}>
        <h5 className={classes.header}>Create a Behavioral Discount</h5>
        <TextField
          fullWidth
          label="Discount Name"
          variant="outlined"
          value={discountName}
          onChange={(e) => setDiscountName(e.target.value)}
        />
        <Grid item sm={2} xs={4} className={classes.serviceType}>
          <div className={classes.label}>Service</div>
          <ToggleButtonGroup
            fullWidth
            color="primary"
            value={serviceType}
            exclusive
            onChange={(e) => handleServiceTypeChange(e.target.value)}
            aria-label="service"
            size="small"
            sx={{ width: 1 }}
          >
            <ToggleButton value="core" sx={{ width: 1 / 4 }}>
              CORE
            </ToggleButton>
            <ToggleButton value="family" sx={{ width: 1 / 4 }}>
              FAMILY
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <FormControl className={classes.radioGroupContainer}>
          <FormLabel id="trigger-type-radio-buttons-group-label">Discount Frequency</FormLabel>
          <RadioGroup
            aria-labelledby="trigger-type-radio-buttons-group-label"
            value={discountFrequency}
            onChange={(e) => handleDiscountFrequencyChange(e.target.value)}
          >
            <div>
              <FormControlLabel value="menu" control={<Radio />} label="A menu week" />
              <span className={classes.frequencyRadioHelperText}>
                Apply once to a selected menu week.
              </span>
            </div>
            <div>
              <FormControlLabel value="recurring" control={<Radio />} label="Recurring" />
              <span className={classes.frequencyRadioHelperText}>
                Discounts are assigned for a set duration
              </span>
            </div>
          </RadioGroup>
        </FormControl>
        <Divider className={classes.divider} />
        <div className={discountFrequency === 'menu' ? classes.fullWidth : classes.hidden}>
          <TextField
            fullWidth
            label="Menu"
            margin="normal"
            onChange={(e) => onMenuChange(e.target.value)}
            select
            size="large"
            value={discountActiveMenuId || ''}
            variant="outlined"
            className={classes.activeMenu}
          >
            {menus.map((menu) => (
              <MenuItem key={menu.ends_at} value={menu.id}>
                {formattedMenu(menu.ends_at)}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div className={discountFrequency === 'recurring' ? classes.fullWidth : classes.hidden}>
          <TextField
            fullWidth
            label="Select the Duration"
            margin="normal"
            select
            size="medium"
            value={durationInWeeks || ''}
            variant="outlined"
            className={classes.durationInWeeks}
            onChange={(e) => onDurationChange(e.target.value)}
            disabled={userCount > 0}
          >
            {Array.from({ length: 13 }, (_, i) => i + 1)
              .concat('For life/event-based')
              .map((num) => (
                <MenuItem key={num} value={num}>
                  {num}
                </MenuItem>
              ))}
          </TextField>
        </div>
      </div>
      {/* BANNER DISPLAY */}
      <div className={classes.root}>
        <h6 className={classes.header}>Banner Display</h6>
        <BannerPreview
          themeIdentifier={bannerTheme}
          promotionText={discountPromoText}
          descriptionText={discountDescriptionText}
          imageSrc={temporaryImage}
        />
        <BannerSelector
          currentPhoto={currentPhoto}
          setCurrentPhotoCallback={setCurrentPhoto}
          selectedBannerTheme={bannerTheme}
          selectedDashboardTagText={dashboardTagText}
          selectedDiscountDescriptionText={discountDescriptionText}
          selectedDiscountPromoText={discountPromoText}
          selectedFileName={fileName}
          selectedTemporaryImage={temporaryImage}
          setBannerThemeCallback={setBannerTheme}
          setDashboardTagTextCallback={setDashboardTagText}
          setDiscountDescriptionTextCallback={setDiscountDescriptionText}
          setDiscountPromoTextCallback={setDiscountPromoText}
          setFilenameCallback={setFilename}
          setPhotoFormStateCallback={setPhotoFormState}
          setTemporaryImageCallback={setTemporaryImage}
        />
      </div>
      {/* DISCLAIMER SECTION */}
      <div className={classes.root}>
        <h6 className={classes.header}>Disclosures</h6>
        <TextField
          fullWidth
          multiline
          rows={5}
          label="Disclosures"
          variant="outlined"
          value={disclaimerText}
          onChange={(e) => setDisclaimerText(e.target.value)}
          helperText="For clarity, please include the offer name with each disclaimer."
          className={classes.containers}
        />
      </div>
      {/* TRIGGER SECTION */}
      <div className={classes.root}>
        <h6 className={classes.header}>Trigger</h6>
        <div className={classes.radioGroupContainer}>
          <FormControl>
            <FormLabel id="trigger-type-radio-buttons-group-label">Trigger Type</FormLabel>
            <RadioGroup
              aria-labelledby="trigger-type-radio-buttons-group-label"
              value={discountTriggerType}
              onChange={(e) => setDiscountTriggerType(e.target.value)}
            >
              <FormControlLabel value="basket" control={<Radio />} label="Basket Trigger" />
              <FormControlLabel
                value="meal"
                control={<Radio />}
                label="Meal Trigger"
                className={discountFrequency === 'recurring' ? classes.hidden : classes.visible}
              />
            </RadioGroup>
          </FormControl>
        </div>
        <Divider className={classes.divider} />
        <Container
          className={
            discountTriggerType === 'meal'
              ? [classes.visible, classes.containerStyle]
              : classes.hidden
          }
          disableGutters
        >
          <MealSelector
            meals={filteredMeals}
            selectedMeals={discountTriggerMeals}
            selectedMealsCallback={(selectedmeals) => setDiscountTriggerMeals(selectedmeals)}
          />
        </Container>
        <Container className={classes.containerStyle}>
          <TextField
            fullWidth
            label="Number of Orderable Units to Trigger Reward"
            variant="outlined"
            value={discountEligibleOrderableUnits}
            onChange={(e) => setDiscountEligibleOrderableUnits(e.target.value)}
          />
        </Container>
      </div>
      {/* REWARD SECTION */}
      <div className={classes.root}>
        <h6 className={classes.header}>Reward</h6>
        <div className={classes.radioGroupContainer}>
          <FormControl>
            <FormLabel id="trigger-type-radio-buttons-group-label">Reward Type</FormLabel>
            <RadioGroup
              aria-labelledby="trigger-type-radio-buttons-group-label"
              value={discountRewardType}
              onChange={(e) => handleRewardTypeChange(e.target.value)}
            >
              <FormControlLabel value="basket" control={<Radio />} label="Basket Discount" />
              <FormControlLabel
                value="meal"
                control={<Radio />}
                label="Meal Discount"
                className={discountFrequency === 'recurring' ? classes.hidden : classes.visible}
              />
              <FormControlLabel
                value="product_type"
                control={<Radio />}
                label="Product Type Discount"
                className={discountFrequency === 'recurring' ? classes.visible : classes.hidden}
              />
              <FormControlLabel value="pickable_item" control={<Radio />} label="Pickable Item" />
              <FormControlLabel value="shipping" control={<Radio />} label="Free Shipping" />
            </RadioGroup>
          </FormControl>
        </div>
        <Divider
          className={
            discountRewardType === 'pickable_item' || discountRewardType === 'shipping'
              ? classes.hidden
              : classes.divider
          }
        />
        <Container
          className={
            discountRewardType === 'basket'
              ? [classes.visible, classes.containerStyle]
              : classes.hidden
          }
          disableGutters
        >
          <Grid container spacing={2}>
            <Grid item sm={10} xs={8}>
              <div className={discountType === 'cents' ? classes.visible : classes.hidden}>
                <MoneyInput
                  label="Discount Amount"
                  fullWidth
                  disabled={disableAmountInput}
                  defaultValue={discountAmount}
                  onSetFormState={(value) => setDiscountAmount(value)}
                  setFormError={(value) => setFormError(value)}
                />
              </div>
              <div className={discountType === 'percent' ? classes.visible : classes.hidden}>
                <TextField
                  fullWidth
                  label="Discount Amount"
                  variant="outlined"
                  value={discountPercent}
                  InputProps={{
                    inputComponent: PercentFormatter,
                  }}
                  onChange={(e) => setDiscountPercent(e.target.value)}
                />
              </div>
            </Grid>
            <Grid item sm={2} xs={4}>
              <ToggleButtonGroup
                color="primary"
                value={discountType}
                exclusive
                onChange={(e) => handleDiscountTypeChange(e.target.value)}
                aria-label="Discount Type"
                size="large"
                sx={{ width: 1 }}
              >
                <ToggleButton value="cents" sx={{ width: 1 / 2 }}>
                  $
                </ToggleButton>
                <ToggleButton value="percent" sx={{ width: 1 / 2 }}>
                  %
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid>
        </Container>
        <Container
          className={
            discountRewardType === 'meal'
              ? [classes.visible, classes.containerStyle]
              : classes.hidden
          }
        >
          <MealSelector
            meals={filteredMeals}
            selectedMeals={discountRewardMeals}
            selectedMealsCallback={(selectedRewardMeals) =>
              setDiscountRewardMeals(selectedRewardMeals)
            }
          />
          <TextField
            fullWidth
            label="Number of Orderable Units Available for Discount"
            variant="outlined"
            value={maxOrderableUnits}
            onChange={(e) => setMaxOrderableUnits(e.target.value)}
          />
          <Grid container spacing={2}>
            <Grid item sm={10} xs={8}>
              <div className={discountType === 'cents' ? classes.visible : classes.hidden}>
                <MoneyInput
                  fullWidth
                  disabled={disableAmountInput}
                  label="Discount Amount per Orderable Unit"
                  defaultValue={discountAmount}
                  onSetFormState={(value) => setDiscountAmount(value)}
                  setFormError={(value) => setFormError(value)}
                />
              </div>
              <div className={discountType === 'percent' ? classes.visible : classes.hidden}>
                <TextField
                  fullWidth
                  label="Discount Amount per Orderable Unit"
                  variant="outlined"
                  value={discountPercent}
                  InputProps={{
                    inputComponent: PercentFormatter,
                  }}
                  onChange={(e) => setDiscountPercent(e.target.value)}
                />
              </div>
            </Grid>
            <Grid item sm={2} xs={4}>
              <ToggleButtonGroup
                color="primary"
                value={discountType}
                exclusive
                onChange={(e) => handleDiscountTypeChange(e.target.value)}
                aria-label="Discount Type"
                size="large"
                sx={{ width: 1 }}
              >
                <ToggleButton value="cents" sx={{ width: 1 / 2 }}>
                  $
                </ToggleButton>
                <ToggleButton value="percent" sx={{ width: 1 / 2 }}>
                  %
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid>
        </Container>
        <Container
          className={
            discountRewardType === 'product_type'
              ? [classes.visible, classes.containerStyle]
              : classes.hidden
          }
        >
          <ProductTypeSelector
            productTypes={productTypes}
            selectedProductTypeIds={discountRewardProductTypeIds}
            selectedProductTypeIdsCallback={(selectedProductTypeIds) =>
              setDiscountRewardProductTypeIds(selectedProductTypeIds)
            }
          />
          <TextField
            fullWidth
            label="Number of Orderable Units Available for Discount"
            variant="outlined"
            value={maxOrderableUnits}
            onChange={(e) => setMaxOrderableUnits(e.target.value)}
          />
          <Grid container spacing={2}>
            <Grid item sm={10} xs={8}>
              <div className={discountType === 'cents' ? classes.visible : classes.hidden}>
                <MoneyInput
                  fullWidth
                  disabled={disableAmountInput}
                  label="Discount Amount per Orderable Unit"
                  defaultValue={discountAmount}
                  onSetFormState={(value) => setDiscountAmount(value)}
                  setFormError={(value) => setFormError(value)}
                />
              </div>
              <div className={discountType === 'percent' ? classes.visible : classes.hidden}>
                <TextField
                  fullWidth
                  label="Discount Amount per Orderable Unit"
                  variant="outlined"
                  value={discountPercent}
                  InputProps={{
                    inputComponent: PercentFormatter,
                  }}
                  onChange={(e) => setDiscountPercent(e.target.value)}
                />
              </div>
            </Grid>
            <Grid item sm={2} xs={4}>
              <ToggleButtonGroup
                color="primary"
                value={discountType}
                exclusive
                onChange={(e) => handleDiscountTypeChange(e.target.value)}
                aria-label="Discount Type"
                size="large"
                sx={{ width: 1 }}
              >
                <ToggleButton value="cents" sx={{ width: 1 / 2 }}>
                  $
                </ToggleButton>
                <ToggleButton value="percent" sx={{ width: 1 / 2 }}>
                  %
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid>
        </Container>
      </div>
      <div className={classes.root}>
        <h6 className={classes.header}>Display a Meal Card Badge</h6>
        <Container className={classes.containerStyle}>
          <MealBadgeSelector
            disabled={
              discountRewardType === 'basket' ||
              discountRewardType === 'pickable_item' ||
              discountRewardType === 'shipping'
            }
            mealBadges={mealBadges}
            selectedMealBadgeId={mealBadgeId}
            selectedMealBadgeIdCallback={(badgeId) => setMealBadgeId(badgeId)}
          />
        </Container>
      </div>
      {/* SAVE OR CANCEL BUTTONS */}
      <div className={classes.saveOrCancelSection}>
        <span className={classes.buttonContainer}>
          <Button
            color="primary"
            onClick={() =>
              behavioralDiscountId
                ? [revertForm(), setIsDiscountUnsaved(true)]
                : [clearForm(), setIsDiscountUnsaved(true)]
            }
          >
            Cancel
          </Button>
          <Button
            disabled={
              !!(
                !discountName ||
                !discountPromoText ||
                !discountDescriptionText ||
                (!discountAmount &&
                  !discountPercent &&
                  !(discountRewardType === 'pickable_item')) ||
                (discountFrequency === 'menu' && !discountActiveMenuId) ||
                !discountEligibleOrderableUnits ||
                !disclaimerText ||
                posting ||
                formError
              )
            }
            variant="contained"
            color="primary"
            onClick={() => handleFormSave()}
          >
            Save
          </Button>
        </span>
      </div>
    </div>
  );
};

BehavioralDiscountTool.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default BehavioralDiscountTool;
