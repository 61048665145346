import React from 'react';
import PropTypes from 'prop-types';

import { bannerTemplates } from 'lib/helpers/behavioralDiscountTool/bannerHelper';

import Box from '@mui/material/Box';

import Tag from './Tag';

const BannerPreview = ({ classes, themeIdentifier, promotionText, descriptionText, imageSrc }) => {
  const theme = bannerTemplates[themeIdentifier];
  const { bannerBackgroundColor, bannerTagTextColor, bannerTagBackgroundColor, bannerTextColor } =
    theme;

  const heading = promotionText || 'Promotion Text';
  const description = descriptionText || 'Description Text';

  return (
    <Box className={classes.bannerPreviewContainer}>
      <Box
        className={classes.bannerOuterContainer}
        style={{ backgroundColor: bannerBackgroundColor }}
      >
        <Box className={classes.bannerInnerContainer}>
          <Box className={classes.bannerContentContainer}>
            <Box
              style={{
                color: bannerTagTextColor,
                backgroundColor: bannerTagBackgroundColor,
              }}
              className={`${classes.bannerLabelContainer} ${classes.h7}`}
            >
              <Box
                sx={{
                  fontFamily: 'Karla',
                }}
                className={classes.bannerLabelContentContainer}
              >
                <Box className={classes.bannerLabelIcon}>
                  <Tag color={bannerTagTextColor} />
                </Box>
                <Box style={{ color: bannerTagTextColor }}>OFFER</Box>
              </Box>
            </Box>
            <Box
              sx={{
                fontFamily: 'LeMondeCourrier',
              }}
              className={classes.h6}
              style={{ color: bannerTextColor }}
            >
              {heading}
            </Box>
            <Box
              sx={{
                fontFamily: 'Karla',
              }}
              className={classes.h7}
              style={{ color: bannerTextColor }}
            >
              {description}
            </Box>
          </Box>
          {imageSrc && (
            <Box className={classes.bannerImageContainer}>
              <img className={classes.bannerImage} src={imageSrc} alt="Banner Preview" />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

BannerPreview.propTypes = {
  classes: PropTypes.object.isRequired,
  themeIdentifier: PropTypes.string.isRequired,
  promotionText: PropTypes.string.isRequired,
  descriptionText: PropTypes.string.isRequired,
  imageSrc: PropTypes.string,
};

BannerPreview.defaultProps = {
  imageSrc: null,
};

export default BannerPreview;
