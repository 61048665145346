import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { broadcastScrollTopToParent, currentHeight } from 'lib/utils';

// Components
import { FeatureFlag } from 'components/shared';

import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';

import AddIncidentButton from '../AddIncidentButton';
import CancelFields from '../CancelFields';
import DeliveryInfo from '../DeliveryInfo';
import Footer from '../Footer';
import MealsAndCharges from '../MealsAndCharges';
import IncidentSummary from '../IncidentSummary';
import IncidentForm from '../IncidentForm';

const IncidentGrid = ({
  cancelState,
  classes,
  incidentable,
  error,
  errorMessages,
  formStates,
  success,
  successMessages,
  incidents,
  setCancelState,
  setFormStates,
  updateFormState,
}) => {
  const [buttonHeight, setButtonHeight] = useState(null);
  const renderErrorMessages = () => {
    return errorMessages.map((message) => {
      return (
        <Alert key={message} severity="error" variant="filled">
          {message}
        </Alert>
      );
    });
  };

  const renderSuccessMessages = () => {
    return successMessages.map((message) => {
      return (
        <Alert key={message} severity="success" variant="filled">
          {message}
        </Alert>
      );
    });
  };

  const renderForms = () => {
    return Object.keys(formStates).map((formId) => {
      return (
        <IncidentForm
          key={formId}
          formId={formId}
          formStates={formStates}
          setFormStates={setFormStates}
          updateFormState={updateFormState}
        />
      );
    });
  };

  const anyIncidents = incidents.length > 0;

  const findMaxHeight = () => {
    const addButtonPadding = 32;
    return currentHeight() - buttonHeight - addButtonPadding;
  };

  useEffect(() => {
    if (anyIncidents) {
      broadcastScrollTopToParent();
    }
  }, [anyIncidents]);

  return (
    <>
      {/* Here is an example of how to use FeatureFlag. */}
      <FeatureFlag flag="cs_beta_testing">
        <Alert key="cs_beta_testing" severity="warning" variant="filled">
          Testing Beta Features
        </Alert>
      </FeatureFlag>

      {success && renderSuccessMessages()}
      {error && renderErrorMessages()}

      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <div className={classes.incidentablesWrapper} style={{ maxHeight: findMaxHeight() }}>
              <DeliveryInfo incidentable={incidentable} />
              <MealsAndCharges incidentable={incidentable} />
            </div>
          </Grid>
          <Grid item xs={8}>
            {anyIncidents ? (
              <IncidentSummary />
            ) : (
              <>
                {renderForms()}
                <AddIncidentButton
                  formStates={formStates}
                  setFormStates={setFormStates}
                  setButtonHeight={setButtonHeight}
                />
                <CancelFields
                  cancelState={cancelState}
                  cancellable={incidentable.cancellable}
                  setCancelState={setCancelState}
                />
                <Footer
                  cancelState={cancelState}
                  incidentable={incidentable}
                  formStates={formStates}
                />
              </>
            )}
          </Grid>
        </Grid>
      </div>
    </>
  );
};

IncidentGrid.propTypes = {
  cancelState: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  incidents: PropTypes.array,
  incidentable: PropTypes.object.isRequired,
  error: PropTypes.bool,
  errorMessages: PropTypes.array,
  formStates: PropTypes.object.isRequired,
  success: PropTypes.bool,
  successMessages: PropTypes.array,
  setCancelState: PropTypes.func.isRequired,
  setFormStates: PropTypes.func.isRequired,
  updateFormState: PropTypes.func.isRequired,
};

IncidentGrid.defaultProps = {
  error: false,
  errorMessages: [],
  incidents: [],
  success: false,
  successMessages: [],
};

export default IncidentGrid;
