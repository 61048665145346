import { handleResponse, options } from 'lib/homeChefApi';
import { makeQueryParamURL } from 'lib/utils';
import { APP_API_URI } from 'lib/constants';

const MEF_URL = `${APP_API_URI}/internal/culinary/meal_edit_form`;

export const fetchFormOptions = async () => {
  const response = await fetch(makeQueryParamURL(`${MEF_URL}/form_options`), options);

  return handleResponse(response);
};
