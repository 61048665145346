import React, { useContext } from 'react';
import PropTypes from 'prop-types';

// Material Components
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import { Loading } from 'components/shared';
import { dollarsToCents, numberToDollarString } from 'lib/helpers/money';
import { pluralizeWithNumber } from 'lib/helpers/strings';
import { incidentableShape } from 'lib/helpers/incidentManager/propShapes';
import { SessionContext } from 'contexts/sessionContext';

const Footer = ({
  cancelState,
  classes,
  formStates,
  incidentable,
  onCancelOrder,
  onSubmitIncidents,
  submitting,
}) => {
  const numberOfForms = Object.keys(formStates).length;
  const { employeeId } = useContext(SessionContext);
  const { maxCreditAmountCents, maxRefundAmountCents, maxGiftCardRefundAmountCents } =
    incidentable.creditsAndRefunds;
  const maxTaxAmountCents = incidentable.receipt.find((item) => item.title === 'Tax').value;

  const calculateTotalCredit = () => {
    const allFormStates = Object.values(formStates);

    return allFormStates.reduce((sum, formState) => {
      return sum + formState.creditAmount;
    }, 0);
  };

  const totalCredit = calculateTotalCredit();
  const totalCreditValid = dollarsToCents(totalCredit) <= maxCreditAmountCents;

  const calculateTotalCashRefund = () => {
    const allFormStates = Object.values(formStates);

    return allFormStates.reduce((sum, formState) => {
      return sum + formState.cashRefundTotalAmount;
    }, 0);
  };

  const totalCashRefund = calculateTotalCashRefund();
  const totalCashRefundValid =
    dollarsToCents(totalCashRefund) <=
    maxRefundAmountCents + (maxTaxAmountCents + maxTaxAmountCents);

  const calculateTotalGiftCardRefund = () => {
    const allFormStates = Object.values(formStates);

    return allFormStates.reduce((sum, formState) => {
      return sum + formState.giftCardRefundTotalAmount;
    }, 0);
  };

  const totalGiftCardRefund = calculateTotalGiftCardRefund();
  const totalGiftCardRefundValid =
    dollarsToCents(totalGiftCardRefund) <=
    maxGiftCardRefundAmountCents + (maxTaxAmountCents + maxTaxAmountCents);

  const handleSubmit = (event) => {
    event.preventDefault();

    const arrayOfIncidentsParams = Object.values(formStates);
    const incidentParams = {
      ...cancelState,
      employeeId,
      incidentableId: incidentable.id,
      incidentableType: incidentable.type,
      incidents: arrayOfIncidentsParams,
    };

    if (cancelState.cancel) {
      Promise.all([onSubmitIncidents(incidentParams), onCancelOrder(incidentParams)]);
    } else {
      onSubmitIncidents(incidentParams);
    }
  };

  if (submitting) {
    return (
      <div className={classes.container}>
        <Loading topPadding={false} />
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <Grid container spacing={0} direction="row" justifyContent="flex-end" alignItems="center">
        <FooterItem
          classes={classes}
          label="Total Credit:"
          valid={totalCreditValid}
          value={numberToDollarString(totalCredit)}
        />
        <FooterItem
          classes={classes}
          label="Total Gift Card Refund:"
          valid={totalGiftCardRefundValid}
          value={numberToDollarString(totalGiftCardRefund)}
        />
        <FooterItem
          classes={classes}
          label="Total Charge Refund:"
          valid={totalCashRefundValid}
          value={numberToDollarString(totalCashRefund)}
        />

        <Button
          className={classes.buttonSubmit}
          onClick={handleSubmit}
          disabled={submitting}
          variant="outlined"
        >
          Create {pluralizeWithNumber(numberOfForms, 'Incident')}
        </Button>
      </Grid>
    </div>
  );
};

const FooterItem = ({ classes, label, valid, value }) => {
  const boldStyle = valid ? classes.textBold : classes.textBoldRed;

  return (
    <div className={classes.item}>
      <span className={classes.text}>{label}&nbsp;</span>
      <span className={boldStyle}>{value}</span>
    </div>
  );
};

Footer.propTypes = {
  cancelState: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  formStates: PropTypes.object.isRequired,
  incidentable: incidentableShape.isRequired,
  onCancelOrder: PropTypes.func.isRequired,
  onSubmitIncidents: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
};

Footer.defaultProps = {
  submitting: false,
};

FooterItem.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  valid: PropTypes.bool,
  value: PropTypes.string.isRequired,
};

FooterItem.defaultProps = {
  valid: true,
};

export default Footer;
