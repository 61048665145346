import { options, handleResponse } from 'lib/homeChefApi';
import { mapKeysDeep } from 'lib/helpers/objects';
import { snakeCase } from 'lodash';
import { APP_API_URI } from 'lib/constants';

const MG_URL = `${APP_API_URI}/internal/culinary/menugrid`;

export const getGridData = async ({ quarter, displayGroup }) => {
  const url = `${MG_URL}/grid_data?quarter=${quarter}&display_group=${displayGroup}`;

  const response = await fetch(url, options);
  return handleResponse(response);
};

export const patchSelectionOrder = async ({ stagingGroup, menus, displayGroup }) => {
  const url = `${MG_URL}/update_menu_selections`;

  const transformedStagingGroup = mapKeysDeep(stagingGroup, (_value, key) => {
    return snakeCase(key);
  });

  const transformedMenus = mapKeysDeep(menus, (_value, key) => {
    return snakeCase(key);
  });

  const patchOptions = {
    body: JSON.stringify({
      staging_group: transformedStagingGroup,
      menus: transformedMenus,
      display_group: displayGroup,
    }),
    method: 'PATCH',
  };
  const mergedOptions = { ...options, ...patchOptions };
  const response = await fetch(url, mergedOptions);
  return handleResponse(response);
};

export const createStagingSelections = async ({
  stagingGroup,
  displayGroup,
  culinaryVersionIds,
}) => {
  const url = `${MG_URL}/create_staging_group_selections`;
  const patchOptions = {
    body: JSON.stringify({
      id: stagingGroup.id,
      display_group: displayGroup,
      data: { culinary_version_ids: culinaryVersionIds },
    }),
    method: 'POST',
  };

  const mergedOptions = { ...options, ...patchOptions };
  const response = await fetch(url, mergedOptions);
  return handleResponse(response);
};
