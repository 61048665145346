import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

// Example: https://material-ui.com/components/text-fields/#FormattedInputs.js

// This is a component based on the react-number-format library that has been tuned for use in our app

const PercentFormatter = React.forwardRef((props, ref) => {
  const handleFocus = (event) => event.target.select();
  return (
    <NumberFormat
      allowNegative={false}
      className={props.className}
      decimalScale={0}
      defaultValue={0}
      disabled={props.disabled}
      fixedDecimalScale
      getInputRef={ref}
      onChange={props.onChange}
      onFocus={handleFocus}
      suffix="%"
      value={props.value}
    />
  );
});

PercentFormatter.propTypes = {
  className: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number,
};

PercentFormatter.defaultProps = {
  value: 0,
};

PercentFormatter.displayName = 'PercentFormatter';

export default PercentFormatter;
