import { options, handleResponse } from 'lib/homeChefApi';
import { makeQueryParamURL } from 'lib/utils';
import { dollarsToCents } from 'lib/helpers/money';
import { APP_API_URI } from 'lib/constants';

const IM_URL = `${APP_API_URI}/internal/customer_support/incident_manager`;

export const fetchFormOptions = async () => {
  const response = await fetch(makeQueryParamURL(`${IM_URL}/form_options`), options);

  return handleResponse(response);
};

export const createIncidents = async (incidentParams) => {
  const { employeeId, incidentableId, incidentableType, incidents } = incidentParams;

  const transformedIncidents = incidents.map((incident) => {
    return {
      cause_id: incident.causeId,
      credit_cents: dollarsToCents(incident.creditAmount),
      description: incident.description,
      employee_id: employeeId,
      gift_card_refund_cents: dollarsToCents(incident.giftCardRefundTotalAmount),
      incident_type: incident.incidentType,
      ingredient_assignment_id: incident.ingredientId,
      meal_id: incident.mealId,
      cash_refund_cents: dollarsToCents(incident.cashRefundTotalAmount),
      remediation_category_id: incident.remediationCategoryId,
    };
  });

  const transformedParams = {
    incidentable_id: incidentableId,
    incidentable_type: incidentableType,
    incidents: transformedIncidents,
  };

  const response = await fetch(`${IM_URL}/create_incidents`, {
    ...options,
    method: 'POST',
    body: JSON.stringify(transformedParams),
  });

  return handleResponse(response);
};
