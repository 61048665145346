import { options, handleResponse } from 'lib/homeChefApi';
import { APP_API_URI } from 'lib/constants';

export const fetchDisputes = async () => {
  const response = await fetch(
    `${APP_API_URI}/internal/customer_support/dispute_manager/disputes`,
    options
  );
  return handleResponse(response);
};

export const updateEvidence = async ({ disputeId, chargeId }) => {
  const response = await fetch(
    `${APP_API_URI}/internal/customer_support/dispute_manager/send_evidence`,
    {
      ...options,
      method: 'POST',
      body: JSON.stringify({ dispute_id: disputeId, charge_id: chargeId }),
    }
  );
  return handleResponse(response);
};
