import { options, handleResponse } from 'lib/homeChefApi';
import { makeQueryParamURL } from 'lib/utils';
import { APP_API_URI } from 'lib/constants';

export const fetchMeals = async ({ conceptId }) => {
  const response = await fetch(
    makeQueryParamURL(`${APP_API_URI}/internal/culinary/comparison_tool/meals`, {
      concept_id: conceptId,
    }),
    options
  );

  return handleResponse(response);
};
