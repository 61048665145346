import { LIGHT_BLACK } from 'lib/constants';

export default {
  title: {
    color: LIGHT_BLACK,
    fontFamily: 'Roboto',
    fontSize: '16px',
    lineHeight: '20px',
    textAlign: 'left',
    fontWeight: 500,
  },
  titleCell: { padding: '0', maxWidth: '200px' },
  cell: { padding: '4px' },
};
