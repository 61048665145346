import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { apiPost } from 'lib/homeChefApi';

import NetsuiteAutocomplete from 'components/shared/NetsuiteAutocomplete';
import { NETSUITE_TYPE_LINE_OF_BUSINESS } from 'lib/constants';

import { Button, Stack, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const EditCategoryForm = ({
  classes,
  getData,
  handleClose,
  setSnackState,
  workingCategory,
  setJobId,
  isUpdating,
  setIsUpdating,
}) => {
  const [categoryName, setCategoryName] = useState(workingCategory.name);
  const [categoryDescription, setCategoryDescription] = useState(workingCategory.description);
  const [categoryInternalId, setCategoryInternalId] = useState(workingCategory.netsuiteInternalId);
  const [toArchive, setToArchive] = useState(workingCategory.status === 'active');

  useEffect(() => {
    setCategoryName(workingCategory.name);
    setCategoryDescription(workingCategory.description);
    setCategoryInternalId(workingCategory.netsuiteInternalId);
    setToArchive(workingCategory.status === 'active');
  }, [workingCategory]);

  const handleSubmit = async () => {
    const response = await apiPost('internal/retail/category/save_category', {
      id: workingCategory.id,
      name: categoryName,
      description: categoryDescription,
      netsuite_internal_id: categoryInternalId,
    });

    if (response.ok) {
      setSnackState({
        message: `Successfully updated ${workingCategory.name}`,
        severity: 'success',
      });
      handleClose();
      getData();
    } else {
      setSnackState({
        message: `Problem updating ${workingCategory.name}`,
        severity: 'error',
      });
    }
    handleClose();
  };

  const handleArchiveClick = async () => {
    setJobId(null);
    setIsUpdating(true);

    const response = await apiPost('internal/retail/category/set_category_archived', {
      id: workingCategory.id,
      archived: toArchive,
    });

    if (response.ok) {
      setJobId(response.retailCategory.updateAssortmentsJobId);
      handleClose();
      getData();
      setIsUpdating(false);
    } else {
      setSnackState({
        message: `Problem archiving ${workingCategory.name}`,
        severity: 'error',
      });
    }
  };

  return (
    <Stack justifyContent="space-between" spacing={3}>
      <Typography variant="h5" classes={classes.titleBar}>
        Edit Product Line
        <Button onClick={handleClose}>
          <CloseIcon />
        </Button>
      </Typography>
      <TextField
        label="Product Line Info"
        variant="outlined"
        value={categoryName}
        onChange={(e) => setCategoryName(e.target.value)}
        disabled={!toArchive}
      />
      <TextField
        label="Description (optional)"
        variant="outlined"
        value={categoryDescription}
        onChange={(e) => setCategoryDescription(e.target.value)}
        disabled={!toArchive}
      />
      <NetsuiteAutocomplete
        type={NETSUITE_TYPE_LINE_OF_BUSINESS}
        value={categoryInternalId}
        onChange={(id) => setCategoryInternalId(id)}
        disabled={!toArchive}
      />
      <Button variant="contained" onClick={handleSubmit} disabled={isUpdating || !toArchive}>
        {isUpdating ? 'Updating...' : 'Save'}
      </Button>
      <Button variant="outlined" onClick={handleArchiveClick} disabled={isUpdating}>
        {toArchive ? 'Archive' : 'Activate'} Product Line
      </Button>
    </Stack>
  );
};

EditCategoryForm.propTypes = {
  classes: PropTypes.object.isRequired,
  getData: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  isUpdating: PropTypes.bool.isRequired,
  setJobId: PropTypes.func.isRequired,
  setIsUpdating: PropTypes.func.isRequired,
  setSnackState: PropTypes.func.isRequired,
  workingCategory: PropTypes.object.isRequired,
};

export default EditCategoryForm;
