import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Button,
  Radio,
  Container,
  Drawer,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';

const MealBadgeSelector = ({
  classes,
  disabled,
  mealBadges,
  selectedMealBadgeId,
  selectedMealBadgeIdCallback,
}) => {
  const [mealBadgeSelectorOpen, setMealBadgeSelectorOpen] = useState(false);
  const [searchMealBadges, setSearchMealBadges] = useState(mealBadges);

  const handleClose = () => [setMealBadgeSelectorOpen(false), setSearchMealBadges(mealBadges)];

  const handleRemove = () => {
    selectedMealBadgeIdCallback(null);
  };

  const handleClick = (mealBadgeId) => {
    selectedMealBadgeIdCallback(mealBadgeId);

    if (mealBadgeId === selectedMealBadgeId) {
      selectedMealBadgeIdCallback(null);
    }
  };

  const findNameMealBadgeById = (id) => mealBadges.find((mb) => mb.id === id);

  const requestSearch = (searchTerm) => {
    setSearchMealBadges(
      mealBadges.filter((mealBadge) =>
        mealBadge.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  };

  useEffect(() => {
    setSearchMealBadges(mealBadges);
  }, [mealBadges]);

  return (
    <Container disableGutters maxWidth={false} className={classes.container}>
      <h6 className={classes.label}>Display a badge on eligible reward meals (optional)</h6>
      {selectedMealBadgeId && mealBadges.length !== 0 && (
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow className={classes.tableRow}>
                <TableCell
                  component="th"
                  id={`title-${findNameMealBadgeById(selectedMealBadgeId)}`}
                  scope="row"
                  padding="none"
                  className={classes.imageRow}
                >
                  <img
                    src={findNameMealBadgeById(selectedMealBadgeId).icon.url}
                    alt={findNameMealBadgeById(selectedMealBadgeId).name}
                    className={classes.image}
                  />
                </TableCell>
                <TableCell className={classes.nameCell}>
                  <div className={classes.mealBadgeName}>
                    {findNameMealBadgeById(selectedMealBadgeId).name}
                  </div>
                </TableCell>
                <TableCell align="right" className={classes.trashIconCell}>
                  <IconButton onClick={() => handleRemove()} size="large">
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Container className={selectedMealBadgeId ? classes.hidden : classes.visible} disableGutters>
        <Button
          disabled={disabled}
          variant="outlined"
          color="primary"
          onClick={() => setMealBadgeSelectorOpen(true)}
          startIcon={<SearchIcon className={classes.searchIcon} />}
        >
          SEARCH ALL BADGES
        </Button>
      </Container>
      <Drawer anchor="right" open={mealBadgeSelectorOpen} classes={{ paper: classes.drawerPaper }}>
        <Container key="bd_meal_badge_selector">
          <Grid container direction="column" justifyContent="flex-start" spacing={0} height="100vh">
            <Grid item xs={12} maxHeight="12vh">
              <Box className={classes.titleBar}>
                Add a Badge
                <IconButton onClick={() => handleClose()} className={classes.closeBtn} size="large">
                  <CloseIcon />
                </IconButton>
              </Box>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Search..."
                type="search"
                onInput={(e) => requestSearch(e.target.value)}
                size="small"
              />
            </Grid>
            <Grid item xs={12} maxHeight="81vh" overflow="hidden" sx={{ overflowY: 'auto' }}>
              <TableContainer>
                <Table>
                  <TableBody>
                    {searchMealBadges.map((mealBadge) => (
                      <TableRow
                        key={mealBadge.id}
                        hover
                        onClick={() => handleClick(mealBadge.id)}
                        role="checkbox"
                        aria-checked={mealBadge.id === selectedMealBadgeId}
                        selected={mealBadge.id === selectedMealBadgeId}
                      >
                        <TableCell padding="checkbox">
                          <Radio checked={mealBadge.id === selectedMealBadgeId} />
                        </TableCell>
                        <TableCell
                          component="th"
                          id={`title-${mealBadge.id}`}
                          scope="row"
                          padding="none"
                        >
                          <img
                            src={mealBadge.icon.url}
                            alt={mealBadge.name}
                            className={classes.image}
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          id={`title-${mealBadge.id}`}
                          scope="row"
                          padding="none"
                        >
                          {mealBadge.name}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid
              item
              xs={12}
              justifyContent="flex-start"
              width="100%"
              minHeight="50px"
              maxHeight="7vh"
            >
              <Button
                onClick={() => handleClose()}
                variant="contained"
                disabled={!selectedMealBadgeId}
                sx={{ width: '100%', height: '40px', marginTop: '8px' }}
              >
                Add
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Drawer>
    </Container>
  );
};

MealBadgeSelector.propTypes = {
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  mealBadges: PropTypes.arrayOf(PropTypes.object),
  selectedMealBadgeId: PropTypes.number,
  selectedMealBadgeIdCallback: PropTypes.func.isRequired,
};

MealBadgeSelector.defaultProps = {
  mealBadges: [],
  selectedMealBadgeId: null,
};

export default MealBadgeSelector;
