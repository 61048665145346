import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { startCase } from 'lodash';

import { postCompoundIngredientState } from 'redux/compoundIngredientRecipeForm/actions';

import Button from '@mui/material/Button';

const StateActionButton = ({ compoundIngredientId, eventType, disabled }) => {
  const isSubmitting = useSelector(
    (state) => state.compoundIngredientRecipeForm.compoundIngredientRecipe.submitting
  );

  const dispatch = useDispatch();

  const postChanges = () => {
    dispatch(postCompoundIngredientState({ compoundIngredientId, eventType }));
  };

  const handleClick = (event) => {
    event.preventDefault();
    postChanges();
  };

  return (
    <Button onClick={handleClick} disabled={isSubmitting || disabled} variant="outlined">
      {startCase(eventType)}
    </Button>
  );
};

StateActionButton.propTypes = {
  compoundIngredientId: PropTypes.number.isRequired,
  eventType: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

StateActionButton.defaultProps = {
  disabled: false,
};

export default StateActionButton;
