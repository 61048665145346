import { options, handleResponse } from 'lib/homeChefApi';
import { APP_API_URI } from 'lib/constants';

export const fetchAllAppConfigurations = async () => {
  const url = `${APP_API_URI}/internal/application_configurations`;
  const response = await fetch(url, options);

  return handleResponse(response);
};

export const addConfigurationGroup = async (params) => {
  const { configGroup } = params;
  const url = `${APP_API_URI}/internal/application_configurations/group`;
  const response = await fetch(url, {
    ...options,
    method: 'POST',
    body: JSON.stringify(configGroup),
  });

  return handleResponse(response);
};

export const updateConfigurationGroup = async (params) => {
  const { configGroup } = params;
  const url = `${APP_API_URI}/internal/application_configurations/group/${configGroup.id}`;
  const response = await fetch(url, {
    ...options,
    method: 'PATCH',
    body: JSON.stringify(configGroup),
  });

  return handleResponse(response);
};

export const deleteConfigurationGroup = async (params) => {
  const { configGroup } = params;
  const url = `${APP_API_URI}/internal/application_configurations/group/${configGroup.groupId}`;
  const response = await fetch(url, {
    ...options,
    method: 'DELETE',
    body: JSON.stringify(configGroup),
  });

  return handleResponse(response);
};

export const updatingConfigurationKey = async (params) => {
  const { configKey } = params;
  const url = `${APP_API_URI}/internal/application_configurations/keys/${configKey.id}`;
  const response = await fetch(url, {
    ...options,
    method: 'PATCH',
    body: JSON.stringify({
      key: configKey.key,
      configuration_group_id: configKey.groupId,
      name: configKey.name,
      configuration_values_attributes: configKey.values,
    }),
  });

  return handleResponse(response);
};

export const deletingConfigurationKey = async (params) => {
  const { keyId } = params;
  const url = `${APP_API_URI}/internal/application_configurations/keys/${keyId}`;
  const response = await fetch(url, {
    ...options,
    method: 'DELETE',
  });

  return handleResponse(response);
};

export const creatingConfigurationKey = async (params) => {
  const { name, key, type, groupId } = params;
  const url = `${APP_API_URI}/internal/application_configurations/keys`;
  const response = await fetch(url, {
    ...options,
    method: 'POST',
    body: JSON.stringify({
      name,
      key,
      type,
      configuration_group_id: groupId,
    }),
  });

  return handleResponse(response);
};

export const restartingNow = async () => {
  const url = `${APP_API_URI}/internal/application_configurations/restart_now`;
  const response = await fetch(url, {
    ...options,
    method: 'POST',
  });

  return handleResponse(response);
};
