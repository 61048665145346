import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';

const IngredientCheckboxes = ({ classes, handleFormState, ingredients }) => {
  const [chosen, setChosen] = useState('');

  const handleChange = (event) => {
    const newChosen = chosen !== event.target.name ? event.target.name : '';

    setChosen(newChosen);
    handleFormState({ target: { name: 'ingredientId', value: newChosen } });
  };

  return (
    <Grid className={classes.inner} container spacing={0}>
      <Grid item xs={12}>
        <p className={classes.inputLabel}>Ingredient (optional)</p>
      </Grid>
      {ingredients.map((ingredient) => {
        const ingredientId = ingredient.id.toString();
        return (
          <Grid item key={ingredient.id} xs={6}>
            <Checkbox
              checked={chosen === ingredientId}
              onChange={handleChange}
              name={ingredientId}
            />
            <span className={classes.inputlabel}>{ingredient.name}</span>
          </Grid>
        );
      })}
    </Grid>
  );
};

IngredientCheckboxes.propTypes = {
  classes: PropTypes.object.isRequired,
  handleFormState: PropTypes.func.isRequired,
  ingredients: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ).isRequired,
};

export default IngredientCheckboxes;
