import { apiGet } from 'lib/homeChefApi';
import useDebounce from 'lib/useDebounce';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Loading } from 'components/shared';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';

const MealSelector = ({ classes, packingFacility, shipDate, value, onChange }) => {
  const [meals, setMeals] = useState(null);
  const [mealsLoading, setMealsLoading] = useState(false);
  const [mealsError, setMealsError] = useState(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchMeals = useDebounce(async (params) => {
    setMealsLoading(true);
    const response = await apiGet('internal/retail/flexible_volume_changes/get_meals', params);
    setMealsLoading(false);
    if (response.ok) {
      setMeals(response.meals);
    } else {
      setMealsError(response.error);
    }
  });

  useEffect(() => {
    setMeals(null);
    onChange(null);
    setMealsError(null);
    if (packingFacility && shipDate) {
      debouncedFetchMeals({
        packing_facility_id: packingFacility.id,
        ship_date: shipDate,
      });
    }
    // We're using onChange in the effect handler above, but we
    // only want to trigger a refetch of meals when the given packing facility
    // or ship date changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [packingFacility, shipDate]);

  const renderMeal = (meal) => (
    <MenuItem
      onClick={() => onChange(meal)}
      key={meal.id}
      value={meal.id}
      disabled={!!meal.disabled_text}
      className={classes.menuItem}
    >
      <Typography>{meal.title}</Typography>
    </MenuItem>
  );

  const selectedMeal = meals?.find((meal) => meal.id === value?.id);

  return (
    <TextField
      id="meal"
      label="Meal"
      value={selectedMeal?.id || ''}
      variant="outlined"
      fullWidth
      select
      SelectProps={{
        MenuProps: {
          anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
          transformOrigin: { vertical: 'top', horizontal: 'center' },
        },
      }}
      InputProps={{
        endAdornment: mealsLoading ? (
          <Loading
            size={20}
            topPadding={false}
            centered={false}
            className={classes.loadingIndicator}
          />
        ) : undefined,
      }}
      disabled={!meals || mealsError}
      error={!!mealsError}
      helperText={mealsError}
    >
      {meals ? (
        meals.map((meal) =>
          meal.disabled_text ? (
            <Tooltip key={meal.id} title={meal.disabled_text}>
              {renderMeal(meal)}
            </Tooltip>
          ) : (
            renderMeal(meal)
          )
        )
      ) : (
        <MenuItem />
      )}
    </TextField>
  );
};

MealSelector.propTypes = {
  classes: PropTypes.object.isRequired,
  packingFacility: PropTypes.object,
  shipDate: PropTypes.string,
  value: PropTypes.object,
  onChange: PropTypes.func,
};

MealSelector.defaultProps = {
  packingFacility: null,
  shipDate: null,
  value: null,
  onChange: () => {},
};

export default MealSelector;
