import { apiGet } from 'lib/homeChefApi';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import { Skeleton } from '@mui/material';

const MenuWeekSelector = ({ value, onChange }) => {
  const [menus, setMenus] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    const fetchMenus = async () => {
      setFetching(true);
      const response = await apiGet('internal/retail/flexible_volume_changes/get_menus');

      setFetching(false);

      if (response.ok) {
        setMenus(response.retailMenus);
      } else {
        setErrorMessage(response.error);
      }
    };

    fetchMenus();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (menus && menus[2] && !value) {
      onChange(menus[2]);
    }
  }, [menus]); // eslint-disable-line react-hooks/exhaustive-deps

  if (errorMessage) {
    return <Alert severity="error">{errorMessage}</Alert>;
  }
  if (menus === null || fetching) {
    return <Skeleton variant="rectangular" width={250} height={56} />;
  }
  return (
    <TextField
      select
      SelectProps={{
        MenuProps: {
          getContentAnchorEl: null,
          anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
          transformOrigin: { vertical: 'top', horizontal: 'center' },
        },
      }}
      sx={{
        minWidth: 250,
      }}
      label="Menu Week"
      value={value === null ? '' : value.id}
      variant="outlined"
    >
      {menus.map((menu) => (
        <MenuItem onClick={() => onChange(menu)} key={menu.id} value={menu.id}>
          {menu.inStoreStartsAt}
        </MenuItem>
      ))}
    </TextField>
  );
};

MenuWeekSelector.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
};

MenuWeekSelector.defaultProps = {
  value: null,
  onChange: () => {},
};

export default MenuWeekSelector;
