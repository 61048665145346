import {
  ISW_TARGET_INGREDIENT_REQUEST,
  ISW_TARGET_INGREDIENT_SUCCESS,
  ISW_TARGET_INGREDIENT_ERROR,
  ISW_USAGE_YIELD,
  ISW_QUANTITY,
  ISW_MEASURE,
  ISW_SET_SWAPABILITY,
  ISW_PORTION_METHOD,
  ISW_INGREDIENT_PACKAGING_ID,
} from 'lib/constants';

const initialState = {
  id: null,
  name: null,
  brandName: null,
  displayName: null,
  standardUnit: null,
  state: null,
  usageYield: null,
  portionMethod: null,
  ingredientPackagingId: null,
  adminIngredientLink: null,
  ingredientAssignments: null,
  quantity: null,
  measure: null,

  error: false,
  swapability: false,
  loaded: false,
};

export default (state = initialState, action = { type: null }) => {
  switch (action.type) {
    case ISW_TARGET_INGREDIENT_REQUEST:
      return {
        ...state,
        error: false,
        loaded: false,
      };
    case ISW_TARGET_INGREDIENT_SUCCESS:
      return {
        ...state,
        id: action.response.ingredient.id,
        name: action.response.ingredient.name,
        brandName: action.response.ingredient.brandName,
        displayName: action.response.ingredient.displayName,
        standardUnit: action.response.ingredient.standardUnit,
        state: action.response.ingredient.state,
        usageYield: action.response.ingredient.usageYield.toString(),
        portionMethod: action.response.ingredient.portionMethod,
        ingredientPackagingId: action.response.ingredient.ingredientPackagingId,
        adminIngredientLink: action.response.ingredient.adminIngredientLink,
        ingredientAssignments: action.response.ingredient.ingredientAssignments,
        dropdownOptions: action.response.ingredient.dropdownOptions,
        error: false,
        loaded: true,
      };
    case ISW_TARGET_INGREDIENT_ERROR:
      return {
        ...state,
        error: true, // to be used in upcoming PR - AA 11/13/2020
        loaded: false,
      };
    case ISW_USAGE_YIELD:
      return {
        ...state,
        usageYield: action.payload.usageYield,
      };
    case ISW_QUANTITY:
      return {
        ...state,
        quantity: action.payload.quantity,
      };
    case ISW_MEASURE:
      return {
        ...state,
        measure: action.payload.measure,
      };
    case ISW_PORTION_METHOD:
      return {
        ...state,
        portionMethod: action.payload.portionMethod,
      };
    case ISW_INGREDIENT_PACKAGING_ID:
      return {
        ...state,
        ingredientPackagingId: action.payload.ingredientPackagingId,
      };
    case ISW_SET_SWAPABILITY:
      return {
        ...state,
        swapability: action.payload.isSwappable,
      };
    default:
      return state;
  }
};
