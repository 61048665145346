import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { apiPost } from 'lib/homeChefApi';

import { Button, Stack, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const EditFulfillerForm = ({ classes, getData, handleClose, workingFulfiller }) => {
  const [fulfillerName, setFulfillerName] = useState(workingFulfiller.name);
  const [fulfillerDescription, setFulfillerDescription] = useState(workingFulfiller.description);
  const [toArchive, setToArchive] = useState(workingFulfiller.status === 'active');

  useEffect(() => {
    setFulfillerName(workingFulfiller.name);
    setFulfillerDescription(workingFulfiller.description);
    setToArchive(workingFulfiller.status === 'active');
  }, [workingFulfiller]);

  const handleSubmit = async () => {
    await apiPost('internal/retail/fulfiller/save_fulfiller', {
      id: workingFulfiller.id,
      name: fulfillerName,
      description: fulfillerDescription,
    });

    handleClose();
    getData();
  };

  const handleArchiveClick = async () => {
    await apiPost('internal/retail/fulfiller/save_fulfiller', {
      id: workingFulfiller.id,
      status: workingFulfiller.status === 'active' ? 'archived' : 'active',
    });
    handleClose();
    getData();
  };

  return (
    <Stack justifyContent="space-between" spacing={3}>
      <Typography variant="h5" classes={classes.titleBar}>
        Edit Fulfiller
        <Button onClick={handleClose}>
          <CloseIcon />
        </Button>
      </Typography>
      <TextField
        label="Fulfiller Name"
        variant="outlined"
        value={fulfillerName}
        onChange={(e) => setFulfillerName(e.target.value)}
        disabled={!toArchive}
      />
      <TextField
        label="Description (optional)"
        variant="outlined"
        value={fulfillerDescription}
        onChange={(e) => setFulfillerDescription(e.target.value)}
        disabled={!toArchive}
      />
      <Button variant="contained" onClick={handleSubmit} disabled={!toArchive}>
        Save
      </Button>
      <Button variant="outlined" onClick={handleArchiveClick}>
        {toArchive ? 'Archive' : 'Activate'}
      </Button>
    </Stack>
  );
};

EditFulfillerForm.propTypes = {
  classes: PropTypes.object.isRequired,
  getData: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  workingFulfiller: PropTypes.object.isRequired,
};

export default EditFulfillerForm;
