import { HC_GREEN, LIGHT_GREEN } from 'lib/constants';

export default {
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
  },
  label: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '24px',
    letterSpacing: '0.15px',
    opacity: '60%',
    margin: '0',
  },
  headerCount: {
    fontWeight: '700',
  },
  drawerPaper: {
    width: '500px',
  },
  nameCell: {
    paddingLeft: '16px',
    paddingTop: '12px',
    paddingBottom: '12px',
  },
  trashIconCell: {
    padding: '0px',
  },
  addProductTypesButton: {
    padding: '6px 16px',
  },
  addIcon: {
    paddingBottom: '2px',
  },
  productTypeName: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '20px',
    letterSpacing: '0.15px',
  },
  titleBar: {
    position: 'relative',
    fontSize: '20px',
    padding: '16px',
  },
  closeBtn: {
    position: 'absolute',
    top: '0px',
    right: '0px',
  },
  toggleButton: {
    '&.Mui-selected': {
      color: HC_GREEN,
      backgroundColor: LIGHT_GREEN,
    },
  },
};
