import PropTypes from 'prop-types';

const menuShape = PropTypes.shape({
  type: PropTypes.string,
  id: PropTypes.number,
  date: PropTypes.string,
});

const classificationShape = PropTypes.shape({
  channel: PropTypes.string,
  plan: PropTypes.string,
  mealType: PropTypes.string,
  status: PropTypes.string,
  servings: PropTypes.number,
  mealPlan: PropTypes.string,
});

const photoShape = PropTypes.shape({
  id: PropTypes.number,
  url: PropTypes.string,
  identifier: PropTypes.string,
  category: PropTypes.string,
  title: PropTypes.string,
});

const cookingFactsShape = PropTypes.shape({
  prepTime: PropTypes.string,
  cookWithin: PropTypes.number,
  difficultyLevel: PropTypes.number,
  spiceLevel: PropTypes.number,
});

const nutritionShape = PropTypes.shape({
  fat: PropTypes.number,
  carbs: PropTypes.number,
  sodium: PropTypes.number,
  protein: PropTypes.number,
  calories: PropTypes.number,
});

const ingredientShape = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  brandName: PropTypes.string,
  ingredientName: PropTypes.string,
  quantity: PropTypes.number,
  measure: PropTypes.string,
  isProtein: PropTypes.bool,
});

const recipeStepShape = PropTypes.shape({
  id: PropTypes.number,
  description: PropTypes.string,
  url: PropTypes.string,
  step: PropTypes.number,
  title: PropTypes.string,
});

const beforeYouCookInstructionShape = PropTypes.shape({
  id: PropTypes.number,
  instruction: PropTypes.string,
});

const disposableItemShape = PropTypes.shape({
  name: PropTypes.string,
  quantity: PropTypes.number,
});

export const mealShape = PropTypes.shape({
  id: PropTypes.number,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  versionId: PropTypes.string,
  versionNotes: PropTypes.string,
  lastUpdatedDate: PropTypes.string,
  lastUpdatedName: PropTypes.string,
  adminUrl: PropTypes.string,
  chef: PropTypes.string,
  menus: PropTypes.arrayOf(menuShape),
  classification: classificationShape,
  photos: PropTypes.arrayOf(photoShape),
  cookingFacts: cookingFactsShape,
  nutrition: nutritionShape,
  ingredients: PropTypes.arrayOf(ingredientShape),
  recipeSteps: PropTypes.arrayOf(recipeStepShape),
  beforeYouCookInstructions: PropTypes.arrayOf(beforeYouCookInstructionShape),
  whatYouNeed: PropTypes.arrayOf(PropTypes.string),
  saltQuantity: PropTypes.number,
  oliveOilQuantity: PropTypes.number,
  disposableItems: PropTypes.arrayOf(disposableItemShape),
});
