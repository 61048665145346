import {
  BLUEY_BLUE,
  BLUEY_DARK_BLUE,
  CREAM_110,
  GREEN_130,
  GRAPE,
  LEMON_100,
  LIGHT_BLACK,
  LIGHT_GREEN,
  ORANGE_100,
  PINK_100,
  TEAL,
  WHITE,
} from 'lib/constants';

import Default from './images/default.svg';
import Kale from './images/kale.svg';
import Lemon from './images/lemon.svg';
import Grape from './images/grape.svg';
import Teal from './images/teal.svg';
import BLUEY from './images/bluey.svg';
import FDFL from './images/fdfl.svg';

export const defaultTemplates = {
  default: {
    bannerBackgroundColor: LIGHT_GREEN,
    bannerTextColor: LIGHT_BLACK,
    bannerTagBackgroundColor: LIGHT_BLACK,
    bannerTagTextColor: LIGHT_GREEN,
    image: Default,
    descriptor: 'Light green with grey text',
  },
  teal: {
    bannerBackgroundColor: TEAL,
    bannerTextColor: LIGHT_BLACK,
    bannerTagBackgroundColor: LIGHT_BLACK,
    bannerTagTextColor: TEAL,
    image: Teal,
    descriptor: 'Teal with grey text',
  },
  lemon: {
    bannerBackgroundColor: LEMON_100,
    bannerTextColor: GREEN_130,
    bannerTagBackgroundColor: GREEN_130,
    bannerTagTextColor: LEMON_100,
    image: Lemon,
    descriptor: 'Lemon with dark green text',
  },
  grape: {
    bannerBackgroundColor: GRAPE,
    bannerTextColor: CREAM_110,
    bannerTagBackgroundColor: CREAM_110,
    bannerTagTextColor: GRAPE,
    image: Grape,
    descriptor: 'Grape with cream text ',
  },
  kale: {
    bannerBackgroundColor: GREEN_130,
    bannerTextColor: CREAM_110,
    bannerTagBackgroundColor: CREAM_110,
    bannerTagTextColor: GREEN_130,
    image: Kale,
    descriptor: 'Dark green with cream text',
  },
};

export const promotionalTemplates = {
  ffl: {
    bannerBackgroundColor: PINK_100,
    bannerTextColor: LIGHT_BLACK,
    bannerTagBackgroundColor: ORANGE_100,
    bannerTagTextColor: WHITE,
    image: FDFL,
    descriptor: 'Free Dessert for Life',
  },
  bluey: {
    bannerBackgroundColor: BLUEY_BLUE,
    bannerTextColor: BLUEY_DARK_BLUE,
    bannerTagBackgroundColor: BLUEY_DARK_BLUE,
    bannerTagTextColor: BLUEY_BLUE,
    image: BLUEY,
    descriptor: 'Bluey Promo',
  },
};

export const bannerTemplates = { ...defaultTemplates, ...promotionalTemplates };

export const calculateFileSize = (bytes) => (bytes / 1024).toFixed(2);

export const getColorTheme = (backgroundColor) => {
  let selectedTemplate;

  Object.entries(bannerTemplates).forEach(([key, value]) => {
    if (value.bannerBackgroundColor === backgroundColor) {
      selectedTemplate = key;
    }
  });

  return selectedTemplate;
};

export const camelToSnakeCase = (camelCase) => {
  return camelCase.replace(/[A-Z]/g, (match, offset) => {
    return (offset ? '_' : '') + match.toLowerCase();
  });
};
