// Dependencies
import React from 'react';
import PropTypes from 'prop-types';

// Components
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';

import { formOptionsShape, formStateShape } from 'lib/helpers/incidentManager/propShapes';
import IngredientCheckboxes from './IngredientCheckboxes';
import Input from '../../Input';

const DeliveryIncidentFields = ({
  classes,
  formOptions,
  formState,
  handleFormState,
  incidentable,
}) => {
  const renderErrorTypes = () => {
    return formOptions.errorTypes.map((errorType) => {
      return (
        <MenuItem key={errorType.id} value={errorType.id}>
          {errorType.name}
        </MenuItem>
      );
    });
  };

  const renderSubcategories = () => {
    return formOptions.deliveryRemediationCategories.map((category) => {
      return (
        <MenuItem key={category.id} value={category.id}>
          {category.name}
        </MenuItem>
      );
    });
  };

  const renderMealInput = () => {
    return (
      <Input label="Meal" name="mealId" onChange={handleFormState} select value={formState.mealId}>
        <MenuItem value="">Entire basket affected</MenuItem>
        {incidentable.mealSelections.map((selection) => {
          return (
            <MenuItem key={selection.mealId} value={selection.mealId}>
              {selection.title}
            </MenuItem>
          );
        })}
      </Input>
    );
  };

  const renderIngredientsInput = () => {
    const meal = incidentable.mealSelections.find(
      (selection) => selection.mealId === formState.mealId
    );

    return (
      <IngredientCheckboxes
        classes={classes}
        handleFormState={handleFormState}
        ingredients={meal.ingredients}
      />
    );
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Input
            label="Error Type"
            name="causeId"
            onChange={handleFormState}
            required
            select
            value={formState.causeId}
          >
            <MenuItem value="">Error Type</MenuItem>
            {renderErrorTypes()}
          </Input>
        </Grid>
        <Grid item xs={6}>
          <Input
            label="Subcategory"
            name="remediationCategoryId"
            onChange={handleFormState}
            required
            select
            value={formState.remediationCategoryId}
          >
            <MenuItem value="">Subcategory</MenuItem>
            {renderSubcategories()}
          </Input>
        </Grid>
      </Grid>

      {renderMealInput()}
      {formState.mealId && renderIngredientsInput()}

      <Input
        label="Description"
        multiline
        name="description"
        onChange={handleFormState}
        required
        value={formState.description}
      />
    </>
  );
};

DeliveryIncidentFields.propTypes = {
  classes: PropTypes.object.isRequired,
  formOptions: formOptionsShape.isRequired,
  formState: formStateShape.isRequired,
  handleFormState: PropTypes.func.isRequired,
  incidentable: PropTypes.object.isRequired,
};

export default DeliveryIncidentFields;
