import { handleResponse, options } from 'lib/homeChefApi';
import { makeQueryParamURL } from 'lib/utils';
import { APP_API_URI } from 'lib/constants';

const CIRF_URL = `${APP_API_URI}/internal/culinary/compound_ingredient_recipe_form`;

export const fetchFormOptions = async () => {
  const response = await fetch(makeQueryParamURL(`${CIRF_URL}/form_options`), options);

  return handleResponse(response);
};
