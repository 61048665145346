import { options, handleResponse } from 'lib/homeChefApi';
import { makeQueryParamURL } from 'lib/utils';
import { APP_API_URI } from 'lib/constants';

export const CT_URL = `${APP_API_URI}/internal/culinary/comparison_tool`;
export const updateMeals = async (params) => {
  const queryUrlParams = {
    source_meal_id: params.sourceMealId,
    target_meal_ids: params.targetMealIds,
  };

  const queryUrl = makeQueryParamURL(`${CT_URL}/update_meal_version_data`, queryUrlParams);
  const response = await fetch(queryUrl, { ...options, method: 'POST' });

  return handleResponse(response);
};
